"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
  function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
    function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
    function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
  var _ = { label: 0, sent: function () { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
  return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
  function verb(n) { return function (v) { return step([n, v]); }; }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0: case 1: t = op; break;
        case 4: _.label++; return { value: op[1], done: false };
        case 5: _.label++; y = op[1]; op = [0]; continue;
        case 7: op = _.ops.pop(); _.trys.pop(); continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
          if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
          if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
          if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
          if (t[2]) _.ops.pop();
          _.trys.pop(); continue;
      }
      op = body.call(thisArg, _);
    } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
    if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
  }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportHelper = exports.EID = void 0;
var wifc = require("web-ifc");
// eslint-disable-next-line import/no-mutable-exports
exports.EID = 1;
function real(v) {
  return { type: 4, value: v };
}
function ref(v) {
  return { type: 5, value: v };
}
function empty() {
  return { type: 6 };
}
function str(v) {
  return { type: 1, value: v };
}
function enm(v) {
  return { type: 3, value: v };
}
var ExportHelper = /** @class */ (function () {
  function ExportHelper(m, api) {
    this.model = m;
    this.api = api;
  }
  ExportHelper.prototype.Write = function (lineObject) {
    return __awaiter(this, void 0, void 0, function () {
      var rawLineData;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            rawLineData = {
              ID: lineObject.expressID,
              type: lineObject.type,
              arguments: lineObject.ToTape()
            };
            return [4 /*yield*/, this.api.WriteRawLineData(this.model, rawLineData)];
          case 1:
            _a.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  ExportHelper.prototype.Point = function (o) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, pt;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            pt = new wifc.IfcCartesianPoint(ID, wifc.IFCCARTESIANPOINT, [
              real(o.x),
              real(o.y),
              real(o.z)
            ]);
            return [4 /*yield*/, this.Write(pt)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.PolyLoop = function (os) {
    return __awaiter(this, void 0, Promise, function () {
      var refs, ID, loop;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0: return [4 /*yield*/, Promise.all(os.map(function (o) { return _this.Point(o); }))];
          case 1:
            refs = _a.sent();
            ID = exports.EID++;
            loop = new wifc.IfcPolyLoop(ID, wifc.IFCPOLYLOOP, refs);
            return [4 /*yield*/, this.Write(loop)];
          case 2:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.FaceOuterBound = function (os) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, bound, _a, _b, _c;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            ID = exports.EID++;
            _b = (_a = wifc.IfcFaceOuterBound).bind;
            _c = [void 0, ID,
            wifc.IFCFACEOUTERBOUND];
            return [4 /*yield*/, this.PolyLoop(os)];
          case 1:
            bound = new (_b.apply(_a, _c.concat([_d.sent(), enm('T')])))();
            return [4 /*yield*/, this.Write(bound)];
          case 2:
            _d.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.Face = function (os) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, face, _a, _b, _c;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            ID = exports.EID++;
            _b = (_a = wifc.IfcFace).bind;
            _c = [void 0, ID, wifc.IFCFACE];
            return [4 /*yield*/, this.FaceOuterBound(os)];
          case 1:
            face = new (_b.apply(_a, _c.concat([[_d.sent()]])))();
            return [4 /*yield*/, this.Write(face)];
          case 2:
            _d.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.ClosedShell = function (faceRefs) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, shell;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            shell = new wifc.IfcClosedShell(ID, wifc.IFCCLOSEDSHELL, faceRefs);
            return [4 /*yield*/, this.Write(shell)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.FacetedBREP = function (faceRefs) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, brep, _a, _b, _c;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            ID = exports.EID++;
            _b = (_a = wifc.IfcFacetedBrep).bind;
            _c = [void 0, ID, wifc.IFCFACETEDBREP];
            return [4 /*yield*/, this.ClosedShell(faceRefs)];
          case 1:
            brep = new (_b.apply(_a, _c.concat([_d.sent()])))();
            return [4 /*yield*/, this.Write(brep)];
          case 2:
            _d.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.ColourRGB = function (r, g, b) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, col;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            col = new wifc.IfcColourRgb(ID, wifc.IFCCOLOURRGB, empty(), real(r), real(g), real(b));
            return [4 /*yield*/, this.Write(col)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.SurfaceStyleShading = function (r, g, b, a) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, col, _a, _b, _c;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            ID = exports.EID++;
            _b = (_a = wifc.IfcSurfaceStyleShading).bind;
            _c = [void 0, ID,
            wifc.IFCSURFACESTYLESHADING];
            return [4 /*yield*/, this.ColourRGB(r, g, b)];
          case 1:
            col = new (_b.apply(_a, _c.concat([_d.sent(), real(a)])))();
            return [4 /*yield*/, this.Write(col)];
          case 2:
            _d.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.SurfaceStyleRendering = function (r, g, b, a) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, col, _a, _b, _c;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            ID = exports.EID++;
            _b = (_a = wifc.IfcSurfaceStyleRendering).bind;
            _c = [void 0, ID,
            wifc.IFCSURFACESTYLERENDERING];
            return [4 /*yield*/, this.ColourRGB(r, g, b)];
          case 1:
            col = new (_b.apply(_a, _c.concat([_d.sent(), real(a),
            empty(),
            empty(),
            empty(),
            empty(),
            empty(),
            empty(),
            enm('NOTDEFINED')])))();
            return [4 /*yield*/, this.Write(col)];
          case 2:
            _d.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.SurfaceStyle = function (name, r, g, b, a) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, col, _a, _b, _c;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            ID = exports.EID++;
            _b = (_a = wifc.IfcSurfaceStyle).bind;
            _c = [void 0, ID,
            wifc.IFCSURFACESTYLE,
            str(name),
            enm(wifc.IfcSurfaceSide.BOTH)];
            return [4 /*yield*/, this.SurfaceStyleShading(r, g, b, a)];
          case 1:
            col = new (_b.apply(_a, _c.concat([[_d.sent()]])))();
            return [4 /*yield*/, this.Write(col)];
          case 2:
            _d.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.PresentationStyleAssignment = function (name, r, g, b, a) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, style, _a, _b, _c;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            ID = exports.EID++;
            _b = (_a = wifc.IfcPresentationStyleAssignment).bind;
            _c = [void 0, ID, wifc.IFCPRESENTATIONSTYLEASSIGNMENT];
            return [4 /*yield*/, this.SurfaceStyle(name, r, g, b, a)];
          case 1:
            style = new (_b.apply(_a, _c.concat([[
              _d.sent()
            ]])))();
            return [4 /*yield*/, this.Write(style)];
          case 2:
            _d.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.ShapePresentationStyleAssignment = function (name, r, g, b, a) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, style, _a, _b, _c;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            ID = exports.EID++;
            _b = (_a = wifc.IfcPresentationStyleAssignment).bind;
            _c = [void 0, ID, wifc.IFCPRESENTATIONSTYLEASSIGNMENT];
            return [4 /*yield*/, this.ShapeStyleAssignment(name, r, g, b, a)];
          case 1:
            style = new (_b.apply(_a, _c.concat([[
              _d.sent()
            ]])))();
            return [4 /*yield*/, this.Write(style)];
          case 2:
            _d.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.ShapeStyleAssignment = function (name, r, g, b, a) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, style, _a, _b, _c;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            ID = exports.EID++;
            _b = (_a = wifc.IfcSurfaceStyle).bind;
            _c = [void 0, ID, wifc.IFCSURFACESTYLE, str(name), enm('BOTH')];
            return [4 /*yield*/, this.SurfaceStyleRendering(r, g, b, a)];
          case 1:
            style = new (_b.apply(_a, _c.concat([[
              _d.sent()
            ]])))();
            return [4 /*yield*/, this.Write(style)];
          case 2:
            _d.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.StyledItem = function (item, style) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, s;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            s = new wifc.IfcStyledItem(ID, wifc.IFCSTYLEDITEM, item, [style], empty());
            return [4 /*yield*/, this.Write(s)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.StyledItemContext = function (style) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, s;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            s = new wifc.IfcStyledItem(ID, wifc.IFCSTYLEDITEM, empty(), [style], empty());
            return [4 /*yield*/, this.Write(s)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.StyledRepresentationContext = function (context, name, description, style) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, s;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            s = new wifc.IfcStyledRepresentation(ID, wifc.IFCSTYLEDREPRESENTATION, context, str(name), str(description), style);
            return [4 /*yield*/, this.Write(s)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.ShapeBREP = function (brepRefs) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, shape;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            shape = new wifc.IfcShapeRepresentation(ID, wifc.IFCSHAPEREPRESENTATION, empty(), str('Body'), str('Brep'), brepRefs);
            return [4 /*yield*/, this.Write(shape)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.ProductDefinitionShape = function (shapeRefs) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, def;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            def = new wifc.IfcProductDefinitionShape(ID, wifc.IFCPRODUCTDEFINITIONSHAPE, empty(), empty(), shapeRefs);
            return [4 /*yield*/, this.Write(def)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.Product = function (constructor, typeID, productShape, placement) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, pt;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            pt = new constructor(ID, typeID, str(Math.random().toString(16).substring(2, 8)), empty(), str('name'), empty(), str('label'), placement, productShape, str(''), empty());
            return [4 /*yield*/, this.Write(pt)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.Building = function (typeID, placement) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, pt;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            pt = new wifc.IfcBuilding(ID, typeID, str(Math.random().toString(16).substr(2, 8)), empty(), str('name'), str('description'), str('label'), placement, empty(), str(''), enm(wifc.IfcElementCompositionEnum.ELEMENT), empty(), empty(), empty());
            return [4 /*yield*/, this.Write(pt)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.BuildingStorey = function (typeID, placement) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, pt;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            pt = new wifc.IfcBuildingStorey(ID, typeID, str(Math.random().toString(16).substr(2, 8)), empty(), str('name'), str('description'), str('label'), placement, empty(), str(''), enm(wifc.IfcElementCompositionEnum.ELEMENT), empty());
            return [4 /*yield*/, this.Write(pt)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.Site = function (typeID, placement) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, pt;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            pt = new wifc.IfcSite(ID, typeID, str(Math.random().toString(16).substr(2, 8)), empty(), str('name'), str('description'), str('label'), placement, empty(), str(''), enm(wifc.IfcElementCompositionEnum.ELEMENT), empty(), empty(), empty(), empty(), empty());
            return [4 /*yield*/, this.Write(pt)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.RelContainedInSpatialStructure = function (typeID, buildingStorey, elementsList) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, pt;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            pt = new wifc.IfcRelContainedInSpatialStructure(ID, typeID, str(Math.random().toString(16).substr(2, 8)), empty(), str('name'), str('description'), elementsList, buildingStorey);
            return [4 /*yield*/, this.Write(pt)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.RelAggregates = function (typeID, element, elementsList) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, pt;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            pt = new wifc.IfcRelAggregates(ID, typeID, str(Math.random().toString(16).substr(2, 8)), empty(), str('name'), str('description'), element, elementsList);
            return [4 /*yield*/, this.Write(pt)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.Dir = function (o) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, pt;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            pt = new wifc.IfcDirection(ID, wifc.IFCDIRECTION, [real(o.x), real(o.y), real(o.z)]);
            return [4 /*yield*/, this.Write(pt)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.Point2D = function (o) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, pt;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            pt = new wifc.IfcCartesianPoint(ID, wifc.IFCCARTESIANPOINT, [real(o.x), real(o.y)]);
            return [4 /*yield*/, this.Write(pt)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.AxisPlacement = function (o) {
    return __awaiter(this, void 0, Promise, function () {
      var locationID, ID, pt;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0: return [4 /*yield*/, this.Point(o)];
          case 1:
            locationID = _a.sent();
            ID = exports.EID++;
            pt = new wifc.IfcAxis2Placement3D(ID, wifc.IFCAXIS2PLACEMENT3D, locationID, empty(), empty());
            return [4 /*yield*/, this.Write(pt)];
          case 2:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.AxisPlacement2D = function (o) {
    return __awaiter(this, void 0, Promise, function () {
      var locationID, ID, pt;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0: return [4 /*yield*/, this.Point2D(o)];
          case 1:
            locationID = _a.sent();
            ID = exports.EID++;
            pt = new wifc.IfcAxis2Placement2D(ID, wifc.IFCAXIS2PLACEMENT2D, locationID, empty());
            return [4 /*yield*/, this.Write(pt)];
          case 2:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.Placement = function (o) {
    return __awaiter(this, void 0, Promise, function () {
      var axisID, ID, pt;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0: return [4 /*yield*/, this.AxisPlacement(o)];
          case 1:
            axisID = _a.sent();
            ID = exports.EID++;
            pt = new wifc.IfcLocalPlacement(ID, wifc.IFCLOCALPLACEMENT, empty(), axisID);
            return [4 /*yield*/, this.Write(pt)];
          case 2:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.CircleProfile = function (rad, o) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, pt, _a, _b, _c;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            ID = exports.EID++;
            _b = (_a = wifc.IfcCircleProfileDef).bind;
            _c = [void 0, ID,
            wifc.IFCCIRCLEPROFILEDEF,
            enm(wifc.IfcProfileTypeEnum.AREA),
            str('column-prefab')];
            return [4 /*yield*/, this.AxisPlacement2D(o)];
          case 1:
            pt = new (_b.apply(_a, _c.concat([_d.sent(), real(rad)])))();
            return [4 /*yield*/, this.Write(pt)];
          case 2:
            _d.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.Project = function (context, name, description) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, pt, _a, _b, _c;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            ID = exports.EID++;
            _b = (_a = wifc.IfcProject).bind;
            _c = [void 0, ID,
            wifc.IFCPROJECT,
            str(Math.random().toString(16).substr(2, 8)),
            empty(),
            str(name),
            str(description),
            empty(),
            empty(),
            empty(),
            [context]];
            return [4 /*yield*/, this.UnitAssignment()];
          case 1:
            pt = new (_b.apply(_a, _c.concat([_d.sent()])))();
            return [4 /*yield*/, this.Write(pt)];
          case 2:
            _d.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.RepresentationContext = function (pos, north) {
    return __awaiter(this, void 0, void 0, function () {
      var ID, pt, _a, _b, _c;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            ID = exports.EID++;
            _b = (_a = wifc.IfcGeometricRepresentationContext).bind;
            _c = [void 0, ID,
            wifc.IFCGEOMETRICREPRESENTATIONCONTEXT,
            str('Model'),
            empty(),
            real(3),
            real(1e-5)];
            return [4 /*yield*/, this.AxisPlacement(pos)];
          case 1:
            _c = _c.concat([_d.sent()]);
            return [4 /*yield*/, this.Dir(north)];
          case 2:
            pt = new (_b.apply(_a, _c.concat([_d.sent()])))();
            return [4 /*yield*/, this.Write(pt)];
          case 3:
            _d.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.RepresentationSubContext = function (context, identifier, description, enumerated) {
    return __awaiter(this, void 0, void 0, function () {
      var ID, pt;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            pt = new wifc.IfcGeometricRepresentationSubContext(ID, wifc.IFCGEOMETRICREPRESENTATIONSUBCONTEXT, str(identifier), str(description), str('*'), str('*'), str('*'), str('*'), context, empty(), enm(enumerated), empty());
            return [4 /*yield*/, this.Write(pt)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.UnitAssignment = function () {
    return __awaiter(this, void 0, void 0, function () {
      var ID, lst, _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, pt;
      return __generator(this, function (_s) {
        switch (_s.label) {
          case 0:
            ID = exports.EID++;
            lst = [];
            _b = (_a = lst).push;
            return [4 /*yield*/, this.SiUnit(enm(wifc.IfcUnitEnum.LENGTHUNIT), enm(wifc.IfcSIUnitName.METRE))];
          case 1:
            _b.apply(_a, [_s.sent()]);
            _d = (_c = lst).push;
            return [4 /*yield*/, this.SiUnit(enm(wifc.IfcUnitEnum.AREAUNIT), enm(wifc.IfcSIUnitName.SQUARE_METRE))];
          case 2:
            _d.apply(_c, [_s.sent()]);
            _f = (_e = lst).push;
            return [4 /*yield*/, this.SiUnit(enm(wifc.IfcUnitEnum.VOLUMEUNIT), enm(wifc.IfcSIUnitName.CUBIC_METRE))];
          case 3:
            _f.apply(_e, [_s.sent()]);
            _h = (_g = lst).push;
            return [4 /*yield*/, this.SiUnit(enm(wifc.IfcUnitEnum.MASSUNIT), enm(wifc.IfcSIUnitName.GRAM))];
          case 4:
            _h.apply(_g, [_s.sent()]);
            _k = (_j = lst).push;
            return [4 /*yield*/, this.SiUnit(enm(wifc.IfcUnitEnum.SOLIDANGLEUNIT), enm(wifc.IfcSIUnitName.STERADIAN))];
          case 5:
            _k.apply(_j, [_s.sent()]);
            _m = (_l = lst).push;
            return [4 /*yield*/, this.SiUnit(enm(wifc.IfcUnitEnum.TIMEUNIT), enm(wifc.IfcSIUnitName.SECOND))];
          case 6:
            _m.apply(_l, [_s.sent()]);
            _p = (_o = lst).push;
            return [4 /*yield*/, this.SiUnit(enm(wifc.IfcUnitEnum.THERMODYNAMICTEMPERATUREUNIT), enm(wifc.IfcSIUnitName.DEGREE_CELSIUS))];
          case 7:
            _p.apply(_o, [_s.sent()]);
            _r = (_q = lst).push;
            return [4 /*yield*/, this.SiUnit(enm(wifc.IfcUnitEnum.LUMINOUSINTENSITYUNIT), enm(wifc.IfcSIUnitName.LUMEN))];
          case 8:
            _r.apply(_q, [_s.sent()]);
            pt = new wifc.IfcUnitAssignment(ID, wifc.IFCUNITASSIGNMENT, lst);
            return [4 /*yield*/, this.Write(pt)];
          case 9:
            _s.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.SiUnit = function (unit, name) {
    return __awaiter(this, void 0, void 0, function () {
      var ID, pt;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ID = exports.EID++;
            pt = new wifc.IfcSIUnit(ID, wifc.IFCSIUNIT, empty(), unit, empty(), name);
            return [4 /*yield*/, this.Write(pt)];
          case 1:
            _a.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  ExportHelper.prototype.ExtrudedAreaSolid = function (pos, dir, rad, len) {
    return __awaiter(this, void 0, Promise, function () {
      var ID, pt, _a, _b, _c;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            ID = exports.EID++;
            _b = (_a = wifc.IfcExtrudedAreaSolid).bind;
            _c = [void 0, ID,
            wifc.IFCEXTRUDEDAREASOLID];
            return [4 /*yield*/, this.CircleProfile(rad, { x: 0, y: 0 })];
          case 1:
            _c = _c.concat([_d.sent()]);
            return [4 /*yield*/, this.AxisPlacement(pos)];
          case 2:
            _c = _c.concat([_d.sent()]);
            return [4 /*yield*/, this.Dir(dir)];
          case 3:
            pt = new (_b.apply(_a, _c.concat([_d.sent(), real(len)])))();
            return [4 /*yield*/, this.Write(pt)];
          case 4:
            _d.sent();
            return [2 /*return*/, ref(ID)];
        }
      });
    });
  };
  return ExportHelper;
}());
exports.ExportHelper = ExportHelper;
