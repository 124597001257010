import React, { useCallback, useEffect, useRef, useState, memo } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import {
  Popover,
  // List,
  ListItem,
  Button
} from "@mui/material";
import {
  MeshLambertMaterial
} from 'three';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { HexColorPicker } from "react-colorful";
import useClickOutside from "./useClickOutside";
import "./style.css"

const useStyles = makeStyles((theme) => ({
  button: {
    color: "white",
    backgroundColor: "black",
    width: "100%",
    marginTop: "1em",
    marginBottom: "1em",
  },

}));


const ColorPicker = ({
  eids,
  viewer
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [color, setColor] = useState("#D22B2B");

  const handleSetColor = async () => {
    const models = viewer.context.items.ifcModels;
    const ifcModel = models[0];

    const selectMat = new MeshLambertMaterial({
      transparent: true,
      opacity: 0.6,
      color: color,
      // depthTest: false
    });

    viewer.IFC.loader.ifcManager.createSubset({
      modelID: ifcModel.modelID,
      ids: eids,
      applyBVH: true,
      scene: ifcModel.parent,
      material: selectMat,
      removePrevious: true,
      customID: 'full-model-subset'
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div
      className="picker"
    >
      <Button
        edge="end"
        aria-label="comments"
        className="swatch"
        // className={classes.button}
        aria-describedby={id}
        style={{ backgroundColor: color }}
        onClick={handleClick}
      >
        {/* <ColorLensIcon /> */}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        className="color-picker"
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ListItem
          button
        // onClick={(e) => handleExportPropertiesToCsv(e, eids)}
        >
          {/* <ListItemIcon>
            <img src={CSVFileIcon} style={{ width: '2em', height: '2em' }} />
          </ListItemIcon>
          <ListItemText primary="Export propriétées" /> */}
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <HexColorPicker color={color} onChange={setColor} />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={handleSetColor}
              >
                Colorisation
                  </Button>
            </Grid>
          </Grid>
        </ListItem>
      </Popover>
    </div>
  );
};

export default ColorPicker;