import React, { useEffect, useState } from "react";
import {
  Typography,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  Popover,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Tooltip,
  Paper
} from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import DescriptionIcon from "@material-ui/icons/Description";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as WebIFC from "web-ifc";
import { Grid } from "@mui/material";
import {
  Scene,
  WebGLRenderer,
  PerspectiveCamera,
  BoxGeometry,
  MeshLambertMaterial,
  Mesh,
  Color,
  DoubleSide,
  MathUtils,
  EdgesGeometry,
  LineBasicMaterial,
  MeshBasicMaterial,
  Vector2,
  Box3,
  Vector3
} from "three";
import { IfcViewerAPI } from 'web-ifc-viewer';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    width: "100%",
  },
  cardExpanded: {
    position: "absolute",
    top: "0px",
    zIndex: 1000,
    left: "0px",
    right: "0px",
    opacity: "0.95",
    width: ({ width }) => width,
    height: ({ height }) => height,
    maxWidth: window.innerWidth - 175,
    maxHeight: window.innerHeight - 175,
  },
  card: {
    position: "absolute",
    top: "0px",
    zIndex: 100,
    left: "0px",
    right: "0px",
    opacity: "0.95",
    width: ({ width }) => width,
    height: ({ height }) => height,
    maxWidth: window.innerWidth - 175,
    maxHeight: window.innerHeight - 175,
  },
  cardContent: {
    opacity: "0.95",
    height: "85%",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
  mapContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 400,
  },
  map: {
    width: '100%',
    height: '100%'
  }
}));

const Data = ({
  ifcElement,
  isLoading
}) => {
  const classes = useStyles();

  function DecodeIFCString(ifcString) {
    const ifcUnicodeRegEx = /\\X2\\(.*?)\\X0\\/giu;
    let resultString = ifcString;
    let match = ifcUnicodeRegEx.exec(ifcString);
    while (match) {
      const unicodeChar = String.fromCharCode(parseInt(match[1], 16));
      resultString = resultString.replace(match[0], unicodeChar);
      match = ifcUnicodeRegEx.exec(ifcString);
    }
    return resultString;
  }

  return (
    <>
      {isLoading ? (
        <Grid container>
          <Grid item md={12} style={{ textAlign: "center" }}>
            <CircularProgress color="inherit" />
          </Grid>
        </Grid>
      ) : (
        <>
          {ifcElement && (
            <>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Attributes
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableBody>
                        <TableRow key={0}>
                          <TableCell>{`ExpressId`}</TableCell>
                          <TableCell>{`${ifcElement.expressID}`}</TableCell>
                        </TableRow>
                        <TableRow key={1}>
                          <TableCell>{`GlobalId`}</TableCell>
                          <TableCell>{`${ifcElement.GlobalId.value}`}</TableCell>
                        </TableRow>
                        <TableRow key={2}>
                          <TableCell>{`Name`}</TableCell>
                          <TableCell>{`${ifcElement.name}`}</TableCell>
                        </TableRow>
                        <TableRow key={3}>
                          <TableCell>{`Type`}</TableCell>
                          <TableCell>{`${ifcElement.type}`}</TableCell>
                        </TableRow>
                        <TableRow key={4}>
                          <TableCell>{`Predifined Type`}</TableCell>
                          <TableCell>{`${ifcElement.PredefinedType ? ifcElement.PredefinedType.value : 'UNDEFINED'}`}</TableCell>
                        </TableRow>
                        <TableRow key={5}>
                          <TableCell>{`Description`}</TableCell>
                          <TableCell>{`${ifcElement.description ? ifcElement.description : 'UNDEFINED'}`}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              {ifcElement.psets.length > 0 &&
                ifcElement.psets.map((pset, i) => (
                  <Accordion key={i}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        className={classes.heading}
                      >{`${DecodeIFCString(pset.Name.value)}`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableBody>
                            {pset.HasProperties &&
                              pset.HasProperties.length > 0 &&
                              pset.HasProperties.map((property, index) => (
                                <TableRow key={index}>
                                  <TableCell>{`${property.label}`}</TableCell>
                                  <TableCell>
                                    {property.description &&
                                      property.description !== "" && (
                                        <Tooltip
                                          title={`${property.description}`}
                                          placement="top-start"
                                        >
                                          <IconButton>
                                            <InfoIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                  </TableCell>
                                  {RegExp(`^http`).test(property.value) ? (
                                    <TableCell>
                                      <a
                                        href={`${property.value}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >{`${property.value}`}</a>
                                    </TableCell>
                                  ) : (
                                    <TableCell>{`${property.value}`}</TableCell>
                                  )}
                                  <TableCell>{`${property.unit}`}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Data;
