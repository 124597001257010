import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { makeStyles, Fab, Button } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  TextField,
  List,
  Paper,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  Badge,
  ListItemText,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Popover,
  Grid,
  LinearProgress,
  Box,
  Typography,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    width: "100%",
  },
  cardExpanded: {
    position: "absolute",
    top: "0px",
    zIndex: 1000,
    left: "0px",
    right: "0px",
    opacity: "0.95",
    width: ({ width }) => width,
    height: ({ height }) => height,
    maxWidth: window.innerWidth - 175,
    maxHeight: window.innerHeight - 175,
  },
  card: {
    position: "absolute",
    top: "0px",
    zIndex: 100,
    left: "0px",
    right: "0px",
    opacity: "0.95",
    width: ({ width }) => width,
    height: ({ height }) => height,
    maxWidth: window.innerWidth - 175,
    maxHeight: window.innerHeight - 175,
  },
  cardContent: {
    opacity: "0.95",
    height: "85%",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
  avatar: {
    backgroundColor: "transparent",
    width: theme.spacing(7),
    height: theme.spacing(7),
    // padding: '5px',
    borderRadius: "0px",
  },
  fab: {
    backgroundColor: "white",
  },
  button: {
    color: "white",
    backgroundColor: "black",
    width: "100%",
    marginTop: "1em",
    marginBottom: "1em",
  },
  linearProgress: {
    width: "90%",
    margin: "1em",
    left: 0,
    right: 0,
    //position: 'absolute',
    // top: '25%',
    // transform: 'translateY(-25%)'
  },
  textField: {
    width: '100%',
    border: '1px'
  },
}));

const baseStyle = {
  flex: 1,
  display: "flex",
  height: "150px",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  width: "100%",
  borderColor: "#ddddd",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const AddModel = ({
  db,
  setModels
}) => {
  const classes = useStyles();

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );


  //set the state and property
  const [modelTitle, setModelTitle] = useState("");
  const [modelContent, setModelContent] = useState("");
  const [modelFileSize, setModelFileSize] = useState("");
  const [modelFile, setModelFile] = useState("");

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      getFile(acceptedFiles);
    }
  }, []);


  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".ifc",
  });


  //read the file and decode it
  const getFile = (e) => {
    console.log(e)

    let reader = new FileReader();
    reader.readAsDataURL(e[0]);
    reader.onload = (ev) => {
      console.log(e[0].size)
      setModelTitle(e[0].path)
      setModelFileSize(e[0].size)
      setModelFile(reader.result);
    }
  }

  //submit 
  const getModelInfo = (e) => {
    e.preventDefault();
    if (modelTitle !== "" && modelFile !== "") {
      console.log('model file', modelFile)
      let model = {
        title: modelTitle,
        date: Date.now(),
        // content: modelContent,
        size: modelFileSize,
        file: modelFile
      }
      db.models.add(model).then(async () => {
        //retrieve all models inside the database
        let allModels = await db.models.toArray();
        //set the models
        setModels(allModels);
      });
    }
  }

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <br />
        <Typography gutterBottom variant="title2" component="div">
          Ajout d'une maquette:
          </Typography>
      </Grid>
      <Grid item xs={12} align="center" justify="center" alignItems="center">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} name="file" />
          {isDragActive ? (
            <p>
              Importer votre fichier ici ou cliquez pour selectionner un
              fichier
            </p>
          ) : (
            <p>
              Importer votre fichier ici ou cliquez pour selectionner un
              fichier
            </p>
          )}
        </div>
        <ul>{files}</ul>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.button}
          // type="submit"
          // value="Submit"
          onClick={getModelInfo}
        >
          Ajout
        </Button>
      </Grid>
      {/* </form> */}
    </Grid>
  );

}

export default AddModel;