import React, { useEffect, useRef } from "react";
import { DonutChart } from "@carbon/charts-react";
import { PieChart } from "@carbon/charts-react";
// Styles
import "@carbon/charts/styles.css";
import "@carbon/styles/css/styles.css";

import * as WebIFC from "web-ifc";

export default function Graph({
  ifcModelsClasses,
  viewer,
  setEids
}) {
  const chartRef = useRef(null);

  const chartOnClick = async ({ detail }) => {
    if (detail.datum.data.group) {
      console.log('select', detail.datum.data.group)
      const newEids = await viewer.getAllItemsOfType(0, WebIFC[`${detail.datum.data.group.toUpperCase()}`], false, false);
      setEids(newEids);
      await viewer.IFC.pickIfcItemsByID(0, newEids);
    }
  }


  useEffect(() => {
    // You can see the current dispatched events here.
    // https://carbon-design-system.github.io/carbon-charts/documentation/modules/_interfaces_events_.html
    chartRef.current.chart.services.events.addEventListener(
      "pie-slice-click",
      chartOnClick
    );
  }, [chartRef]);

  // Unmount
  useEffect(
    () => () => {
      if (chartRef.current) {
        chartRef.current.chart.services.events.removeEventListener(
          "pie-slice-click",
          chartOnClick
        );
      }
    },
    []
  );

  const chartData = ifcModelsClasses.map((ifcClass) => {
    return {
      "group": `${ifcClass.name}`,
      "value": ifcClass.eids.length
    }
  });

  const chartOptions = {
    title: "IFC Class",
    resizable: true,
    // donut: {
    //   center: {
    //     label: "Classes"
    //   }
    // },
    // pie: {
    //   labels: {
    //     enabled: false
    //   }
    // },
    // color: {
    //   pairing: {
    //     option: 3
    //   }
    // },
    height: "400px"
  };

  return (
    <>
      {/* <DonutChart ref={chartRef} data={chartData} options={chartOptions} /> */}
      <PieChart ref={chartRef} data={chartData} options={chartOptions} />
    </>
  );
}
