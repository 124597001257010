import { useEffect, useState } from 'react';
import * as WebIFC from 'web-ifc';
// import { IfcAPI } from "web-ifc/web-ifc-api";
import {
  IFCBUILDINGSTOREY,
  IFCPROJECT,
  IFCSPACE,
  IFCOPENINGELEMENT,
  IFCWALLSTANDARDCASE,
  IFCWALL,
  IFCSTAIR,
  IFCCOLUMN,
  IFCSLAB,
  IFCROOF,
  IFCFOOTING,
  IFCFURNISHINGELEMENT,
  IFCOWNERHISTORY,
  IFCRELDEFINESBYPROPERTIES,
  IFCRELCONTAINEDINSPATIALSTRUCTURE,
  IFCPROPERTYSET,
  IFCPROPERTYSINGLEVALUE,
  IfcFurnishingElement,
  UNKNOWN,
  STRING,
  LABEL,
  ENUM,
  REAL,
  REF,
  EMPTY,
  SET_BEGIN,
  SET_END,
  LINE_END,
  IfcAPI
} from 'web-ifc';
import Dexie from "dexie";
import axios from "axios";
import {
  BoxGeometry,
  MeshLambertMaterial,
  Mesh,
  Color,
  DoubleSide,
  MathUtils,
  EdgesGeometry,
  LineBasicMaterial,
  MeshBasicMaterial,
  Vector3,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { BuildModel } from './Utils/addColumns';
import { IFCExporter } from './Utils/IfcExporter';
import { ExportHelper, EID } from './Utils/ExportHelper';
import { TriDymeExportHelper } from './Utils/TriDymeExportHelper';
import { geometryTypes } from "./Utils/geometry-types";

function UseIfcRenderer({
  eids,
  setEids,
  state,
  setState
}) {
  const [models, setModels] = useState([]);
  const [elements, setElements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const initIndexDB = async () => {
    //set the database 
    const db = new Dexie("tribimDB");
    //create the database store
    db.version(1).stores({
      models: "title, content, file"
    })
    db.open().catch((err) => {
      console.log(err.stack || err)
    });

    return db;
  }


  const meshMaterials = {
    invisibleMaterial: new MeshLambertMaterial({
      transparent: true,
      opacity: 0,
      color: 0x77aaff,
      depthTest: false,
      side: DoubleSide,
    })
  };

  const getModels = async (db) => {
    let allModels = await db.models.toArray();
    console.log('allModels', allModels)
    setModels(allModels);
    return allModels;
  }

  const addTransformControls = (viewer) => {
    const scene = viewer.IFC.context.scene.scene;
    const renderer = viewer.IFC.context.renderer.renderer;
    const camera = viewer.IFC.context.ifcCamera.activeCamera;
    const orbitControls = viewer.IFC.context.ifcCamera.controls;
    const item = new Mesh(
      new BoxGeometry(1, 1, 1),
      new MeshLambertMaterial({
        color: 'red',
        side: DoubleSide
      })
    );
    scene.add(item);

    const transformControls = new TransformControls(camera, renderer.domElement);
    transformControls.addEventListener('change', function () {
      renderer.render(scene, camera);
    });
    transformControls.addEventListener('dragging-changed', function (event) {
      console.log('item', item.position)
      orbitControls.enabled = !event.value;
    });

    scene.add(transformControls);
    transformControls.attach(item);

    window.onkeydown = (e) => {
      if (e.code === "KeyL") {
        console.log("KeyL")
        transformControls.attach(item);
      }
      if (e.code === "KeyP") {
        transformControls.detach(item);
      }
      if (e.code === 'KeyQ') {
        transformControls.setSpace(transformControls.space === 'local' ? 'world' : 'local');
      }
      if (e.code === 'KeyShift') {
        transformControls.setTranslationSnap(100);
        transformControls.setRotationSnap(MathUtils.degToRad(15));
        transformControls.setScaleSnap(0.25);
      }
      if (e.code === 'KeyT') {
        transformControls.setMode('translate');
      }
      if (e.code === 'KeyR') {
        transformControls.setMode('rotate');
      }
      if (e.code === 'KeyS') {
        transformControls.setMode('scale');
      }
      if (e.code === 'KeySpacebar') {
        transformControls.enabled = !transformControls.enabled;
      }
    }
  }

  function empty() {
    return { type: 6 };
  }
  function str(v) {
    return { type: 1, value: v };
  }
  function ref(v) {
    return { type: 5, value: v }
  }

  function ifcLabel(v) {
    return { type: 2, label: 'IFCLABEL', valueType: 1, value: v }
  }

  function ifcBoolean(v) {
    return { type: 2, label: 'IFCBOOLEAN', valueType: 1, value: v }
  }


  function ifcText(v) {
    return { type: 2, label: 'IFCTEXT', valueType: 1, value: v }
  }

  const handleGetJsonData = async (
    viewer,
    spatialStructure,
    progress
    // excludeGeometry
  ) => {
    // const data = {};
    const data = [];
    let count = 0
    for (const item of spatialStructure) {
      progress({
        loading: true,
        message: `Recherche: ${Math.round(count / spatialStructure.length * 100)} %`
      });
      count++;
      const props = await viewer.IFC.getProperties(0, item.expressID, false, false);
      data.push({
        ...item,
        ...props,
        type: item.type
      })
    }
    return data;
  }


  const addOrEditIfcPropertySingleValue = async ({
    expressID,
    type,
    name,
    description,
    value,
    unit,
    property
  }) => {
    const getPropertyValue = (type, value) => {

      if (value == null) {
        return ifcText(`no value`);
      } else {
        switch (type) {
          case 'IfcLabel':
          case 'IfcText':
            return ifcText(`${value}`);
          case 'IfcBoolean':
            return ifcBoolean(`${value}`);
          default:
            return ifcText(`${value}`);
        }
      }
    }


    let ifcPropertySingleValue = new WebIFC.IfcPropertySingleValue(
      expressID,
      type,
      name ? str(`${name}`) : str(`no name`),
      description ? str(`${description}`) : str(`${name}`),
      // value ? ifcText(`${value}`) : ifcText(`no value`),
      getPropertyValue(property.ifc_type, value),
      unit ? str(`${unit}`) : empty(),
      empty(),
    );

    let rawLineIfcPropertySingleValue = {
      ID: ifcPropertySingleValue.expressID,
      type: ifcPropertySingleValue.type,
      arguments: ifcPropertySingleValue.ToTape()
    };

    return rawLineIfcPropertySingleValue;
  }

  const addOrEditIfcPropertySet = async ({
    expressID,
    type,
    globalId,
    ifcOwnerHistoryExpressID,
    name,
    description,
    ifcPropertySingleValueExpressIDs
  }) => {
    let ifcPropertySet = new WebIFC.IfcPropertySet(
      expressID,
      type,
      globalId ? globalId : empty(),
      ifcOwnerHistoryExpressID ? ifcOwnerHistoryExpressID : empty(),
      name ? name : empty(),
      description ? description : empty(),
      ifcPropertySingleValueExpressIDs
    );

    let rawLineIfcPropertySet = {
      ID: ifcPropertySet.expressID,
      type: ifcPropertySet.type,
      arguments: ifcPropertySet.ToTape()
    };

    return rawLineIfcPropertySet;
  }

  const addOrEditIfcRelDefinesByProperties = async ({
    expressID,
    type,
    globalId,
    ifcOwnerHistoryExpressID,
    name,
    description,
    elementsList,
    ifcPropertySetExpressID
  }) => {
    let ifcRelDefinesByProperties = new WebIFC.IfcRelDefinesByProperties(
      expressID,
      type,
      globalId ? globalId : empty(),
      ifcOwnerHistoryExpressID ? ifcOwnerHistoryExpressID : empty(),
      name ? name : empty(),
      description ? description : empty(),
      elementsList,
      ifcPropertySetExpressID
    );

    let rawLineIfcRelDefinesByProperties = {
      ID: ifcRelDefinesByProperties.expressID,
      type: ifcRelDefinesByProperties.type,
      arguments: ifcRelDefinesByProperties.ToTape()
    };

    return rawLineIfcRelDefinesByProperties;
  }

  function DecodeIFCString(ifcString) {
    const resultString = ifcString?.replace(/'/g, "''");
    // const ifcUnicodeRegEx = /\\X2\\(.*?)\\X0\\/uig;
    // let resultString = ifcString;
    // let match = ifcUnicodeRegEx.exec(ifcString);
    // while (match) {
    //   const unicodeChar = String.fromCharCode(parseInt(match[1], 16));
    //   resultString = resultString.replace(match[0], unicodeChar);
    //   match = ifcUnicodeRegEx.exec(ifcString);
    // }
    return resultString;
  }

  const handleEditIfcProperties = async ({
    viewer,
    modelID,
    properties
  }) => {
    setState({
      ...state,
      loading: true,
      loadingMessage: "Début de l'enrichissement...",
      alertStatus: true,
      alertMessage: "Connected"
    });

    const manager = viewer.IFC.loader.ifcManager;

    let count = 0;
    for (let property of properties) {
      // console.log('property', property)
      // setState({
      //   ...state,
      //   loading: true,
      //   loadingMessage: `Enrichissement: ${Math.round(count / properties.length * 100)} %`
      // });
      count++;
      const updateProperty = await manager.getItemProperties(modelID, parseInt(property.propertyExpressId));
      // console.log('updateProperty', updateProperty);
      updateProperty.Name.value = property.propertyName;
      if (updateProperty.NominalValue == null) {
        updateProperty.NominalValue = await ifcText(property.propertyValue);
      } else {
        // switch (updateProperty.NominalValue.label) {
        //   case 'IFCLABEL':
        //     console.log('String(property.propertyValue)', `${property.propertyValue == null ? "" : `${property.propertyValue}`}`)
        //     // updateProperty.NominalValue.value = property.propertyValue == null ? "" : `${property.propertyValue}`;
        //     updateProperty.NominalValue.value = property.propertyValue == null ? "" : `${property.propertyValue}`;
        //     break;
        //   case 'IFCTEXT':
        //     updateProperty.NominalValue.value = property.propertyValue == null ? "" : `${property.propertyValue}`;
        //     break;
        //   case 'IFCBOOLEAN':
        //     updateProperty.NominalValue.value = property.propertyValue == null ? "" : `${Boolean(property.propertyValue)}`;
        //     break;
        //   case 'IFCTHERMALTRANSMITTANCEMEASURE':
        //     updateProperty.NominalValue.value = property.propertyValue == null ? "" : Number(property.propertyValue);
        //     break;
        //   case 'IFCPLANEANGLEMEASURE':
        //     updateProperty.NominalValue.value = property.propertyValue == null ? "" : Number(property.propertyValue);
        //     break;
        //   default:
        //     updateProperty.NominalValue.value = property.propertyValue == null ? "" : property.propertyValue;
        //     break;
        // }
        if (property.propertyValue == null) {
          updateProperty.NominalValue.value = "";
        } else {
          switch (updateProperty.NominalValue.valueType) {
            case UNKNOWN:
              updateProperty.NominalValue.value = "UNDEFINED";
              break;
            case STRING:
              updateProperty.NominalValue.value = property.propertyValue.toString();
              break;
            case LABEL:
              updateProperty.NominalValue.value = property.propertyValue.toString();
              break;
            case ENUM:
              updateProperty.NominalValue.value = property.propertyValue;
              break;
            case REAL:
              updateProperty.NominalValue.value = Number(property.propertyValue);
              break;
            case REF:
              updateProperty.NominalValue.value = property.propertyValue;
              break;
            case EMPTY:
              updateProperty.NominalValue.value = "";
              break;
            case SET_BEGIN:
              updateProperty.NominalValue.value = property.propertyValue;
              break;
            case SET_END:
              updateProperty.NominalValue.value = property.propertyValue;
              break;
            case LINE_END:
              updateProperty.NominalValue.value = property.propertyValue;
              break;
            default:
              updateProperty.NominalValue.value = property.propertyValue;
              break;
          }
        }
      }
      // updateProperty.Unit = str("m");
      // console.log('updateProperty 1', updateProperty);
      await manager.ifcAPI.WriteLine(modelID, updateProperty);
    }

    setState({
      ...state,
      loading: false,
      alertStatus: true,
      alertMessage: "Enrichissement réussi"
    });
  }

  const editIfcModel = async ({
    viewer,
    modelId,
    expressIDs,
    properties
  }) => {
    setState({
      ...state,
      loading: true,
      loadingMessage: "Début de l'enrichissement...",
      alertStatus: true,
      alertMessage: "Connected"
    });
    // try {
    const manager = await viewer.IFC.loader.ifcManager;
    const allLines = await manager.ifcAPI.GetAllLines(modelId);
    let maxExpressId = 0;
    await Object.keys(allLines._data).forEach(index => {
      maxExpressId = Math.max(maxExpressId, allLines._data[index])
    });

    const elementsList = await expressIDs.map(expressID => {
      return ref(expressID);
    });

    const ifcOwnerHistory = await viewer.IFC.loader.ifcManager.getAllItemsOfType(
      modelId,
      IFCOWNERHISTORY,
      false
    );
    const eidIfcOwnerHistory = (ifcOwnerHistory && ifcOwnerHistory.length > 0) ? ifcOwnerHistory[0] : 1;
    const newPsetOpendthXEids = [];
    const newPsetIFCEids = [];
    const rawLineIfcRelDefinesByPropertiesList = [];
    let propertySingleValueExpressId = maxExpressId + 1;

    let count = 0;

    // CREATION D'UNE BDD DE PROPERTIES ASSOCIE AU EXPRESSIDS
    // for (let expressID of expressIDs) {
    //   const psetList = {
    //     'psetId': {
    //       elemIds: 
    //     }
    //   };

    // }

    if (properties.length > 0) {
      // try {
      for (let property of properties) {
        setState({
          ...state,
          loading: true,
          loadingMessage: `Enrichissement: ${Math.round(count / properties.length * 100)} %`
        });
        count++;
        // Check if corresponding IfcPropertySet exists
        let existingPsetList = [];
        let existingPropertyList = [];

        for (let expressID of expressIDs) {
          const element = await viewer.IFC.getProperties(0, expressID, true, true);
          await element.psets?.map(pset => {
            pset.HasProperties?.forEach(prop => {
              if (prop.Name?.value === property.ifc_property_name || prop.Name?.value === property.property_name) {
                existingPropertyList.push(prop);
              }
            })
          })

          let existingPset = await element.psets?.find(pset => pset.Name?.value === property.ifc_class);

          if (existingPset) {
            existingPsetList.push(existingPset);
          }
        }

        if (existingPropertyList.length > 0) {
          const existingProperty = existingPropertyList[0];
          const rawLineIfcPropertySingleValue = await addOrEditIfcPropertySingleValue({
            expressID: existingProperty.expressID,
            type: IFCPROPERTYSINGLEVALUE,
            name: DecodeIFCString(existingProperty.Name.value),
            description: DecodeIFCString(property.property_definition),
            value: property.text_value,
            unit: property.unit,
            property
          });

          await viewer.IFC.loader.ifcManager.state.api.WriteRawLineData(modelId, rawLineIfcPropertySingleValue);
        } else {
          propertySingleValueExpressId += 1;
          // Add or Edit IfcPropertySingleValue
          const ifcPropertySingleValueEid = propertySingleValueExpressId;
          const rawLineIfcPropertySingleValue = await addOrEditIfcPropertySingleValue({
            expressID: ifcPropertySingleValueEid,
            type: IFCPROPERTYSINGLEVALUE,
            name: DecodeIFCString(property.ifc_property_name),
            description: DecodeIFCString(property.property_definition),
            value: property.text_value,
            unit: property.unit,
            property
          });

          await viewer.IFC.loader.ifcManager.state.api.WriteRawLineData(modelId, rawLineIfcPropertySingleValue);
          if (existingPsetList.length > 0) {
            const existingPset = existingPsetList[0];
            const existingPsetPropertyEids = existingPset.HasProperties?.map(p => ref(p.expressID));
            const rawLineIfcPropertySet = await addOrEditIfcPropertySet({
              expressID: existingPset.expressID,
              type: existingPset.type,
              globalId: existingPset.GlobalId,
              ifcOwnerHistoryExpressID: ref(eidIfcOwnerHistory),
              name: existingPset.Name,
              description: existingPset.Description,
              ifcPropertySingleValueExpressIDs: [...existingPsetPropertyEids, ref(ifcPropertySingleValueEid)]
            })

            await viewer.IFC.loader.ifcManager.state.api.WriteRawLineData(modelId, rawLineIfcPropertySet);
          } else if (property.ifc_class !== 'Pset_opendthx' && property.ifc_class !== '') {
            propertySingleValueExpressId += 1;
            const ifcPropertySetEid = propertySingleValueExpressId;
            const rawLineIfcPropertySet = await addOrEditIfcPropertySet({
              expressID: ifcPropertySetEid,
              type: IFCPROPERTYSET,
              globalId: str(Math.random().toString(16).substr(2, 8)),
              ifcOwnerHistoryExpressID: ref(eidIfcOwnerHistory),
              name: str(property.ifc_class),
              ifcPropertySingleValueExpressIDs: [ref(ifcPropertySingleValueEid)]
            });

            await viewer.IFC.loader.ifcManager.state.api.WriteRawLineData(modelId, rawLineIfcPropertySet);

            newPsetIFCEids.push(ref(ifcPropertySetEid));

            propertySingleValueExpressId += 1;
            const rawLineIfcRelDefinesByProperties = await addOrEditIfcRelDefinesByProperties({
              expressID: propertySingleValueExpressId,
              type: IFCRELDEFINESBYPROPERTIES,
              globalId: str(Math.random().toString(16).substr(2, 8)),
              ifcOwnerHistoryExpressID: ref(eidIfcOwnerHistory),
              name: empty(),
              description: empty(),
              elementsList: elementsList,
              ifcPropertySetExpressID: ref(ifcPropertySetEid)
            })
            // rawLineIfcRelDefinesByPropertiesList.push(rawLineIfcRelDefinesByProperties);
            await viewer.IFC.loader.ifcManager.state.api.WriteRawLineData(modelId, rawLineIfcRelDefinesByProperties);

          } else {
            newPsetOpendthXEids.push(ref(ifcPropertySingleValueEid));
          }
        }

      }
      // } catch (e) {
      //   setState({
      //     ...state,
      //     loading: false,
      //     alertStatus: false,
      //     alertMessage: "Problème lors de l'enrichissement"
      //   });
      // }
    }


    if (newPsetOpendthXEids.length > 0) {
      propertySingleValueExpressId += 1;
      const ifcPropertySetEid = propertySingleValueExpressId;
      const rawLineIfcPropertySet = await addOrEditIfcPropertySet({
        expressID: ifcPropertySetEid,
        type: IFCPROPERTYSET,
        globalId: str(Math.random().toString(16).substr(2, 8)),
        ifcOwnerHistoryExpressID: ref(eidIfcOwnerHistory),
        name: str('Pset_opendthx'),
        ifcPropertySingleValueExpressIDs: newPsetOpendthXEids
      });

      await viewer.IFC.loader.ifcManager.state.api.WriteRawLineData(modelId, rawLineIfcPropertySet);

      propertySingleValueExpressId += 1;
      const rawLineIfcRelDefinesByProperties = await addOrEditIfcRelDefinesByProperties({
        expressID: propertySingleValueExpressId,
        type: IFCRELDEFINESBYPROPERTIES,
        globalId: str(Math.random().toString(16).substr(2, 8)),
        ifcOwnerHistoryExpressID: ref(eidIfcOwnerHistory),
        name: empty(),
        description: empty(),
        elementsList: elementsList,
        ifcPropertySetExpressID: ref(ifcPropertySetEid)
      });

      // rawLineIfcRelDefinesByPropertiesList.push(rawLineIfcRelDefinesByProperties);
      await viewer.IFC.loader.ifcManager.state.api.WriteRawLineData(modelId, rawLineIfcRelDefinesByProperties);
    }

    setState({
      ...state,
      loading: false,
      alertStatus: true,
      alertMessage: "Enrichissement réussi"
    });


    // } catch (e) {
    //   setState({
    //     ...state,
    //     loading: false,
    //     alertStatus: false,
    //     alertMessage: "Problème lors de l'enrichissement"
    //   });
    // }

  }


  const addDataToIfc = async ({
    viewer,
    modelId,
    expressIDs,
    properties
  }) => {
    const allLines = await viewer.IFC.loader.ifcManager.state.api.GetAllLines(modelId);
    console.log('allLines', allLines)
    //const line = await viewer.IFC.loader.ifcManager.state.api.GetLine(modelId, 39116);
    const line = await viewer.IFC.loader.ifcManager.state.api.GetRawLineData(modelId, 39116);
    console.log('lines', line)
    let maxExpressId = 0;
    await Object.keys(allLines._data).forEach(index => {
      maxExpressId = Math.max(maxExpressId, allLines._data[index])
    });

    let propertiesEids = [];
    properties.map(async (property, index) => {
      const propertyEid = maxExpressId + index + 1;
      propertiesEids.push(ref(propertyEid))
      let ifcPropertySingleValue = new WebIFC.IfcPropertySingleValue(
        propertyEid,
        IFCPROPERTYSINGLEVALUE,
        property.property_name ? str(`${property.property_name}`) : empty(),
        property.property_definition ? str(`${property.property_definition}`) : str(`${property.property_name}`),
        property.text_value ? ifcText(`${property.text_value}`) : empty(),
        property.unit ? str(`${property.unit}`) : empty(),
      );

      let rawLineIfcPropertySingleValue = {
        ID: ifcPropertySingleValue.expressID,
        type: ifcPropertySingleValue.type,
        arguments: ifcPropertySingleValue.ToTape()
      };

      await viewer.IFC.loader.ifcManager.state.api.WriteRawLineData(modelId, rawLineIfcPropertySingleValue);
    })

    const ifcOwnerHistory = await viewer.IFC.loader.ifcManager.getAllItemsOfType(
      modelId,
      IFCOWNERHISTORY,
      false
    );
    console.log('ifcOwnerHistory', ifcOwnerHistory)
    const eidIfcOwnerHistory = (ifcOwnerHistory && ifcOwnerHistory.length > 0) ? ifcOwnerHistory[0] : 1;

    console.log('propertiesEids', propertiesEids)
    const psetEid = propertiesEids[propertiesEids.length - 1].value + 1;
    let ifcPropertySet = new WebIFC.IfcPropertySet(
      psetEid,
      IFCPROPERTYSET,
      str(Math.random().toString(16).substr(2, 8)),
      ref(eidIfcOwnerHistory),
      str('Pset_OpendthX'),
      empty(),
      propertiesEids
    );
    console.log('ifcPropertySet', ifcPropertySet)
    let rawLineIfcPropertySet = {
      ID: ifcPropertySet.expressID,
      type: ifcPropertySet.type,
      arguments: ifcPropertySet.ToTape()
    };

    await viewer.IFC.loader.ifcManager.state.api.WriteRawLineData(modelId, rawLineIfcPropertySet);
    console.log('rawLineIfcPropertySet', rawLineIfcPropertySet)
    const elementsList = expressIDs.map(expressID => {
      return ref(expressID);
    })

    let ifcRelDefinesByProperties = new WebIFC.IfcRelDefinesByProperties(
      psetEid + 1,
      IFCRELDEFINESBYPROPERTIES,
      str(Math.random().toString(16).substr(2, 8)),
      ref(eidIfcOwnerHistory),
      empty(),
      empty(),
      elementsList,
      ref(psetEid)
    );
    // setEid(ID++);
    console.log('ifcRelDefinesByProperties', ifcRelDefinesByProperties)
    let rawLineIfcRelDefinesByProperties = {
      ID: ifcRelDefinesByProperties.expressID,
      type: ifcRelDefinesByProperties.type,
      arguments: ifcRelDefinesByProperties.ToTape()
    };
    await viewer.IFC.loader.ifcManager.state.api.WriteRawLineData(modelId, rawLineIfcRelDefinesByProperties);

    console.log('rawLineIfcRelDefinesByProperties', rawLineIfcRelDefinesByProperties)
  }


  const addElementsNewProperties = async ({
    viewer,
    modelID,
    expressIDs,
    properties
  }) => {
    const modelId = modelID ? modelID : 0;
    console.log('ADD PROPERTY')
    if (expressIDs && expressIDs.length > 0) {
      if (properties.length > 0) {
        editIfcModel({
          viewer,
          modelId,
          expressIDs,
          properties
        })
      } else {
        editIfcModel({
          viewer,
          modelId,
          expressIDs,
          properties: [{ property_name: "Epaisseur ou profondeur", text_value: "240.00" }]
        })
      }
    }
  }

  const addGeometryToIfc = async ({
    viewer,
    modelId
  }) => {
    const exporter = new IFCExporter();

    const scene = viewer.IFC.context.scene.scene;
    const renderer = viewer.IFC.context.renderer.renderer;
    const camera = viewer.IFC.context.ifcCamera.activeCamera;
    const orbitControls = viewer.IFC.context.ifcCamera.controls;
    exporter.api.SetWasmPath("../../files/");
    // const loader = new GLTFLoader();

    // let item;
    // await loader.load(
    //   'https://docs.mapbox.com/mapbox-gl-js/assets/34M_17/34M_17.gltf',
    //   // `${formInput.model}`,
    //   async (gltf) => {
    //     item = gltf.scene;
    //     scene.add(gltf.scene);
    //     console.log('gltf scene', gltf.scene)
    //     const meshList = [];
    //     await gltf.scene.traverse(function (child) {
    //       if (child.isMesh) {
    //         meshList.push(child);
    //       }
    //     });

    //     const meshGeometry = meshList.map(mesh => mesh.geometry)
    //     const meshMaterial = meshList.map(mesh => mesh.material)
    //     await exporter.createModelForExport(new Vector3(10, 0, 0), new Vector3(), [
    //       {
    //         geometries: meshGeometry,
    //         geometryMaterials: meshMaterial,
    //         placement: gltf.scene.position,
    //         ifcElementId: IFCFURNISHINGELEMENT,
    //         ifcElementType: IfcFurnishingElement
    //       }
    //     ])

    //   });


    const element = new Mesh(
      new BoxGeometry(2, 1, 0.5),
      new MeshLambertMaterial({
        color: 'red',
        side: DoubleSide
      })
    );
    scene.add(element);

    let position;
    const transformControls = new TransformControls(camera, renderer.domElement);
    transformControls.addEventListener('change', function () {
      renderer.render(scene, camera);
    });
    transformControls.addEventListener('dragging-changed', function (event) {
      position = element.position;
      console.log('elements', elements)
      setElements([
        {
          geometries: [element.geometry],
          geometryMaterials: [element.material],
          placement: new Vector3(position.x, position.z, position.y),
          ifcElementId: IFCFURNISHINGELEMENT,
          ifcElementType: IfcFurnishingElement
        }
      ]);
      orbitControls.enabled = !event.value;
    });

    scene.add(transformControls);
    transformControls.attach(element);

    window.onkeydown = (e) => {
      if (e.code === "KeyL") {
        transformControls.attach(element);
      }
      if (e.code === "KeyP") {
        transformControls.detach(element);
      }
      if (e.code === 'KeyQ') {
        transformControls.setSpace(transformControls.space === 'local' ? 'world' : 'local');
      }
      if (e.code === 'KeyShift') {
        transformControls.setTranslationSnap(100);
        transformControls.setRotationSnap(MathUtils.degToRad(15));
        transformControls.setScaleSnap(0.25);
      }
      if (e.code === 'KeyT') {
        transformControls.setMode('translate');
      }
      if (e.code === 'KeyR') {
        transformControls.setMode('rotate');
      }
      if (e.code === 'KeyS') {
        transformControls.setMode('scale');
      }
      if (e.code === 'KeySpacebar') {
        transformControls.enabled = !transformControls.enabled;
      }
    }

    // const parentEID = 107;
    // const model = viewer.context.items.ifcModels[modelId];
    // await handleAddElementsToModel(exporter, viewer, model, parentEID, [
    //   {
    //     geometries: [element.geometry],
    //     geometryMaterials: [element.material],
    //     placement: new Vector3(0, 0, 10),
    //     ifcElementId: IFCFURNISHINGELEMENT,
    //     ifcElementType: IfcFurnishingElement
    //   }
    // ])
  }

  const handleAddElementsToModel = async (viewer) => {

    let modelID = 0;
    let parentEID = 354;
    let objects = elements;
    const manager = viewer.IFC.loader.ifcManager;
    const allLines = await manager.ifcAPI.GetAllLines(modelID);
    let maxExpressId = 0;
    await Object.keys(allLines._data).forEach(index => {
      maxExpressId = Math.max(maxExpressId, allLines._data[index])
    });
    console.log('maxExpressId', maxExpressId);
    console.log('manager.ifcAPI', manager.ifcAPI);
    const exporter = new TriDymeExportHelper(modelID, manager.ifcAPI, maxExpressId + 1);

    // const context = await exporter.RepresentationContext(new Vector3(), new Vector3());


    const elementsList = [];
    const uuidList = [];
    const styleList = [];
    for (let i = 0; i < objects.length; i++) {
      const meshList = [];
      for (let j = 0; j < objects[i].geometries.length; j++) {
        const mesh = new Mesh(objects[i].geometries[j], objects[i].geometryMaterials[j]);
        meshList.push(mesh);
      }
      const ifcElement = await exportMeshAsIFCProduct(
        exporter,
        uuidList,
        styleList,
        meshList,
        objects[i].ifcElementType,
        objects[i].ifcElementId,
        objects[i].placement
      );
      elementsList.push(ifcElement);
    }
    console.log('parentEID', parentEID);
    console.log('elementsList', elementsList);

    await exporter.RelContainedInSpatialStructure(
      IFCRELCONTAINEDINSPATIALSTRUCTURE,
      { type: 5, value: parentEID },
      elementsList
    );

    const ifcData = await manager.ifcAPI.ExportFileAsIFC(modelID);
    console.log('ifcData', ifcData)
    const ifcDataString = new TextDecoder().decode(ifcData);
    console.log(ifcDataString);
    const ifcBlob = new Blob([ifcData], { type: 'text/plain' });
    const ifcFile = new File([ifcBlob], 'ifcFile.ifc');

    // const scene = viewer.IFC.context.scene.scene;
    // manager.close(model.modelID, scene)
    // const formData = new FormData();
    // formData.append('files', ifcFile);
    const newModel = await viewer.IFC.loadIfc(ifcFile, ifcOnLoadError);

    const newSpatialStructure = await viewer.IFC.getSpatialStructure(
      newModel.modelID,
      false
    );

    const updateSpatialStructures = [
      ...state.spatialStructures.list,
      newSpatialStructure,
    ];

    setState({
      ...state,
      loading: false,
      alertStatus: true,
      alertMessage: "Enrichissement réussi",
      spatialStructures: {
        value: { ...newSpatialStructure },
        list: [...updateSpatialStructures]
      }
    });

    // const ifcData = await manager.ifcAPI.ExportFileAsIFC(model.modelID);
    // const ifcDataString = new TextDecoder().decode(ifcData);
    // console.log(ifcDataString);

    // const element = document.createElement('a');
    // element.setAttribute(
    //   'href',
    //   `data:text/plain;charset=utf-8,${encodeURIComponent(ifcDataString)}`
    // );
    // element.setAttribute('download', 'export.ifc');

    // element.style.display = 'none';
    // document.body.appendChild(element);

    // element.click();

    // document.body.removeChild(element);
  }

  const ifcOnLoadError = async (err) => {
    alert(err.toString());
  };
  const exportMeshAsIFCProduct = async (exporter, uuidList, styleList, mesh, ifcElement, ifcId, objectPlacement) => {
    const brepShapeList = [];
    for (let k = 0; k < mesh.length; k++) {
      const geom = mesh[k].geometry;
      // @ts-ignore
      const index = geom.getIndex().array;
      // @ts-ignore
      const position = geom.attributes.position.array;

      const posStride = geom.attributes.position.itemSize;

      const faces = [];
      for (let i = 0; i < index.length; i += 3) {
        const ia = index[i + 0];
        const ib = index[i + 1];
        const ic = index[i + 2];

        const pta = {
          x: position[ia * posStride + 0],
          y: position[ia * posStride + 1],
          z: position[ia * posStride + 2]
        };
        const ptb = {
          x: position[ib * posStride + 0],
          y: position[ib * posStride + 1],
          z: position[ib * posStride + 2]
        };
        const ptc = {
          x: position[ic * posStride + 0],
          y: position[ic * posStride + 1],
          z: position[ic * posStride + 2]
        };
        // @ts-ignore
        faces.push(await exporter.Face([pta, ptb, ptc]));
      }

      const brep = await exporter.FacetedBREP(faces);
      const brepShape = await exporter.ShapeBREP([brep]);

      // Create material assignment for this geometry
      if (mesh[k].material) {
        const uuid = mesh[k].material.uuid;
        let preexistantMaterial = -1;
        for (let i = 0; i < uuidList.length; i++) {
          if (uuidList[i] === uuid) {
            preexistantMaterial = i;
          }
        }

        if (preexistantMaterial === -1) {
          // @ts-ignore
          const col = mesh[k].material.color;
          // @ts-ignore
          const opacity = mesh[k].material.opacity;

          if (col) {
            const style = await exporter.ShapePresentationStyleAssignment(
              'material',
              col.r,
              col.g,
              col.b,
              1 - opacity
            );
            uuidList.push(uuid);
            styleList.push(style);
            await exporter.StyledItem(brep, style);
          }
        } else {
          await exporter.StyledItem(brep, styleList[preexistantMaterial]);
        }
      }

      brepShapeList.push(brepShape);
    }

    const productDef = await exporter.ProductDefinitionShape(brepShapeList);
    const placement = await exporter.Placement({
      x: objectPlacement.x,
      y: objectPlacement.y,
      z: objectPlacement.z
    });
    const element = await exporter.Product(ifcElement, ifcId, productDef, placement);

    return element;

  }

  const handleCheckNetworkStatus = () => {
    function changeStatus() {
      console.log('navigator.onLine', navigator.onLine)
      setState({
        ...state,
        alertStatus: navigator.onLine,
        alertMessage: navigator.onLine ? 'Connected' : 'Non connecté'
      });
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }

  const handleModelValidation = async () => {
    try {
      const ifcData = await state.viewer.IFC.loader.ifcManager.state.api.ExportFileAsIFC(0);
      const blobIfc = new Blob([ifcData], { type: 'text/plain' });
      const ifcFile = new File([blobIfc], 'ifcFile.png');


      const formData = new FormData();
      formData.append('files', ifcFile);


      // const analysis = await axios.post(process.env.REACT_APP_API_URL, formData);
      // console.log('projectId', analysis.data)
      // const { projectId } = analysis.data;

      // const logs = await axios.get(`${process.env.REACT_APP_API_URL}/log/${projectId}.json`);
      // console.log('logs', logs.data);

      // const jsonData = await JSON.parse(logs.data);
      // console.log('jsonData', jsonData["level"]);
      axios.post(process.env.REACT_APP_API_URL, formData).then((value) => {
        console.log('value.data', value.data)
        return value.data;
      })
        .then(async ({ projectId }) => {
          const logs = await axios.get(`${process.env.REACT_APP_API_URL}/log/${projectId}.json`)
          console.log('logs', logs)
        }).catch((error) => {
          console.log('ERRORRR', error)
        })

      // res.status(200).send(response.data);
    } catch (err) {
      // return res.status(500).json({ error: err });
    }
  }

  const handleInitSubset = async (viewer, modelID) => {
    const models = viewer.context.items.ifcModels;
    const ifcModel = models[modelID];
    const allIDs = Array.from(
      new Set(ifcModel.geometry.attributes.expressID.array)
    );
    const subset = getWholeSubset(viewer, ifcModel, allIDs);
    replaceOriginalModelBySubset(viewer, ifcModel, subset);
  }

  function getWholeSubset(viewer, ifcModel, allIDs) {
    return viewer.IFC.loader.ifcManager.createSubset({
      modelID: ifcModel.modelID,
      ids: allIDs,
      applyBVH: true,
      scene: ifcModel.parent,
      removePrevious: true,
      customID: 'full-model-subset',
    });
  }

  function replaceOriginalModelBySubset(viewer, ifcModel, subset) {
    const items = viewer.context.items;

    items.pickableIfcModels = items.pickableIfcModels.filter(model => model !== ifcModel);
    items.ifcModels = items.ifcModels.filter(model => model !== ifcModel);
    ifcModel.removeFromParent();

    items.ifcModels.push(subset);
    items.pickableIfcModels.push(subset);

    //subset.position.set(ifcModel.modelID * 10, ifcModel.modelID * 10, 10)
  }

  function showAllItems(viewer, ids) {
    viewer.IFC.loader.ifcManager.createSubset({
      modelID: 0,
      ids,
      removePrevious: false,
      applyBVH: true,
      customID: 'full-model-subset',
    });
  }


  return {
    models,
    meshMaterials,
    initIndexDB,
    getModels,
    addTransformControls,
    addElementsNewProperties,
    addGeometryToIfc,
    handleAddElementsToModel,
    handleEditIfcProperties,
    editIfcModel,
    handleCheckNetworkStatus,
    handleGetJsonData,
    handleModelValidation,
    handleInitSubset
  }
};

export {
  UseIfcRenderer
};