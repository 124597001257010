import React, { useEffect, useState } from "react";
import {
  Typography,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  Popover,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Tooltip,
  Paper
} from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import DescriptionIcon from "@material-ui/icons/Description";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as WebIFC from "web-ifc";
import { Grid } from "@mui/material";
import {
  Scene,
  WebGLRenderer,
  PerspectiveCamera,
  BoxGeometry,
  MeshLambertMaterial,
  Mesh,
  Color,
  DoubleSide,
  MathUtils,
  EdgesGeometry,
  LineBasicMaterial,
  MeshBasicMaterial,
  Vector2,
  Box3,
  Vector3
} from "three";
import { IfcViewerAPI } from 'web-ifc-viewer';
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    width: "100%",
  },
  cardExpanded: {
    position: "absolute",
    top: "0px",
    zIndex: 1000,
    left: "0px",
    right: "0px",
    opacity: "0.95",
    width: ({ width }) => width,
    height: ({ height }) => height,
    maxWidth: window.innerWidth - 175,
    maxHeight: window.innerHeight - 175,
  },
  card: {
    position: "absolute",
    top: "0px",
    zIndex: 100,
    left: "0px",
    right: "0px",
    opacity: "0.95",
    width: ({ width }) => width,
    height: ({ height }) => height,
    maxWidth: window.innerWidth - 175,
    maxHeight: window.innerHeight - 175,
  },
  cardContent: {
    opacity: "0.95",
    height: "85%",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
  mapContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 400,
  },
  map: {
    width: '100%',
    height: '100%'
  }
}));

const Geometry = ({
  eids,
  viewer,
  selectedElementID
}) => {
  const classes = useStyles();
  const [elemViewer, setElemViewer] = useState();
  const [contain, setContain] = useState();
  const [speckleUrl, setSpeckleUrl] = useState();

  useEffect(() => {
    async function init() {
      // if (elementViewer) elementViewer.dispose();
      const models = viewer.context.items.ifcModels;
      const ifcModel = models[0];
      viewer.IFC.loader.ifcManager.createSubset({
        modelID: ifcModel.modelID,
        ids: eids,
        applyBVH: true,
        scene: ifcModel.parent,
        removePrevious: true,
        customID: 'single-element-subset',
      });
      const elementMesh = await viewer.IFC.loader.ifcManager.getSubset(0, null, 'single-element-subset');
      console.log('mesh', elementMesh)
      console.log('position', elementMesh.position)
      console.log('geometry', elementMesh.geometry)
      console.log('vertices', Array.from(elementMesh.geometry.attributes.position.array))
      console.log('faces', Array.from(elementMesh.geometry.index.array))
      const res = await axios({
        method: "post",
        url: "http://localhost:5000/speckle/postData",
        headers: {
          "content-type": "application/json"
        },
        data: {
          vertices: Array.from(elementMesh.geometry.attributes.position.array),
          faces: Array.from(elementMesh.geometry.index.array)
        }
      })
      setSpeckleUrl(res.data);
      const container = document.getElementById('element-viewer');
      if (elementMesh) {
        if (elemViewer?.IFC) {
          await elemViewer.dispose();
        }
        setContain(container);
        const elementViewer = new IfcViewerAPI({ container, backgroundColor: new Color(0xffffff) });
        elementViewer.IFC.setWasmPath("../../files/");
        elementViewer.IFC.applyWebIfcConfig({
          COORDINATE_TO_ORIGIN: true,
          USE_FAST_BOOLS: false
        });;
        elementMesh.position.set(0, 0, 0);
        const scene = elementViewer.IFC.context.scene.scene;
        scene.add(elementMesh);
        elementViewer.IFC.context.ifcCamera.targetItem(elementMesh);

        setElemViewer(elementViewer)
        elementViewer.IFC.selector.pickIfcItemsByID(0, eids, true, true)

        container.addEventListener("resize", resizeListener);
        return async () => {
          await elementViewer.dispose();
          container.removeEventListener("resize", resizeListener);
        };
      }

    }
    init();
  }, [eids]);


  const resizeListener = () => {
    console.log('HELLLOOOOO')
    const width = contain.innerWidth;
    const height = contain.innerHeight;
    const renderer = elemViewer.IFC.context.renderer.renderer;
    const camera = elemViewer.IFC.context.ifcCamera.activeCamera;
    camera.aspect = width / height;
    camera.updateProjectionMatrix();

    renderer.setSize(width, height, false);
  };


  return (
    <>
      <Paper className={classes.mapContainer}>
        <div id="element-viewer" style={{
          // position: "absolute",
          height: "100%",
          width: "100%",
          left: "0",
          top: "0",
        }}>
        </div>
        <a href={speckleUrl} target='_blank'>{`${speckleUrl}`}</a>
      </Paper>
    </>
  );
};

export default Geometry;
