"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
  function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
    function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
    function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
  var _ = { label: 0, sent: function () { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
  return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
  function verb(n) { return function (v) { return step([n, v]); }; }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0: case 1: t = op; break;
        case 4: _.label++; return { value: op[1], done: false };
        case 5: _.label++; y = op[1]; op = [0]; continue;
        case 7: op = _.ops.pop(); _.trys.pop(); continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
          if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
          if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
          if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
          if (t[2]) _.ops.pop();
          _.trys.pop(); continue;
      }
      op = body.call(thisArg, _);
    } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
    if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
  }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IFCExporter = void 0;
var web_ifc_1 = require("web-ifc");
var three_1 = require("three");
var ExportHelper_1 = require("./ExportHelper");
var IFCExporter = /** @class */ (function () {
  function IFCExporter(api) {
    if (api === void 0) { api = new web_ifc_1.IfcAPI(); }
    this.api = api;
    this.styleList = [];
    this.uuidList = [];
  }
  IFCExporter.prototype.exportMeshAsIFCProduct = function (exporter, mesh, ifcElement, ifcId, objectPlacement) {
    return __awaiter(this, void 0, Promise, function () {
      var brepShapeList, k, geom, index, position, posStride, faces, i, ia, ib, ic, pta, ptb, ptc, _a, _b, brep, brepShape, uuid, preexistantMaterial, i, col, opacity, style, productDef, placement, element;
      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            brepShapeList = [];
            k = 0;
            _c.label = 1;
          case 1:
            if (!(k < mesh.length)) return [3 /*break*/, 15];
            geom = mesh[k].geometry;
            index = geom.getIndex().array;
            position = geom.attributes.position.array;
            posStride = geom.attributes.position.itemSize;
            faces = [];
            i = 0;
            _c.label = 2;
          case 2:
            if (!(i < index.length)) return [3 /*break*/, 5];
            ia = index[i + 0];
            ib = index[i + 1];
            ic = index[i + 2];
            pta = {
              x: position[ia * posStride + 0],
              y: position[ia * posStride + 1],
              z: position[ia * posStride + 2]
            };
            ptb = {
              x: position[ib * posStride + 0],
              y: position[ib * posStride + 1],
              z: position[ib * posStride + 2]
            };
            ptc = {
              x: position[ic * posStride + 0],
              y: position[ic * posStride + 1],
              z: position[ic * posStride + 2]
            };
            // @ts-ignore
            _b = (_a = faces).push;
            return [4 /*yield*/, exporter.Face([pta, ptb, ptc])];
          case 3:
            // @ts-ignore
            _b.apply(_a, [_c.sent()]);
            _c.label = 4;
          case 4:
            i += 3;
            return [3 /*break*/, 2];
          case 5: return [4 /*yield*/, exporter.FacetedBREP(faces)];
          case 6:
            brep = _c.sent();
            return [4 /*yield*/, exporter.ShapeBREP([brep])];
          case 7:
            brepShape = _c.sent();
            if (!mesh[k].material) return [3 /*break*/, 13];
            uuid = mesh[k].material.uuid;
            preexistantMaterial = -1;
            for (i = 0; i < this.uuidList.length; i++) {
              if (this.uuidList[i] === uuid) {
                preexistantMaterial = i;
              }
            }
            if (!(preexistantMaterial === -1)) return [3 /*break*/, 11];
            col = mesh[k].material.color;
            opacity = mesh[k].material.opacity;
            if (!col) return [3 /*break*/, 10];
            return [4 /*yield*/, exporter.ShapePresentationStyleAssignment('material', col.r, col.g, col.b, 1 - opacity)];
          case 8:
            style = _c.sent();
            this.uuidList.push(uuid);
            this.styleList.push(style);
            return [4 /*yield*/, exporter.StyledItem(brep, style)];
          case 9:
            _c.sent();
            _c.label = 10;
          case 10: return [3 /*break*/, 13];
          case 11: return [4 /*yield*/, exporter.StyledItem(brep, this.styleList[preexistantMaterial])];
          case 12:
            _c.sent();
            _c.label = 13;
          case 13:
            brepShapeList.push(brepShape);
            _c.label = 14;
          case 14:
            k++;
            return [3 /*break*/, 1];
          case 15: return [4 /*yield*/, exporter.ProductDefinitionShape(brepShapeList)];
          case 16:
            productDef = _c.sent();
            return [4 /*yield*/, exporter.Placement({
              x: objectPlacement.x,
              y: objectPlacement.y,
              z: objectPlacement.z
            })];
          case 17:
            placement = _c.sent();
            return [4 /*yield*/, exporter.Product(ifcElement, ifcId, productDef, placement)];
          case 18:
            element = _c.sent();
            return [2 /*return*/, element];
        }
      });
    });
  };
  IFCExporter.prototype.createModelForExport = function (projectPlacement, north, objects) {
    return __awaiter(this, void 0, void 0, function () {
      var model, exporter, context, project, elementsList, i, meshList, j, mesh, ifcElement, placement, buildingStorey, building, site, StoreyList, BuildingList, SiteList, ifcData, ifcDataString, element;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!(this.api.wasmModule === undefined)) return [3 /*break*/, 2];
            return [4 /*yield*/, this.api.Init()];
          case 1:
            _a.sent();
            _a.label = 2;
          case 2:
            console.log('Exporting model...');
            model = this.api.CreateModel();
            exporter = new ExportHelper_1.ExportHelper(model, this.api);
            return [4 /*yield*/, exporter.RepresentationContext(projectPlacement, north)];
          case 3:
            context = _a.sent();
            return [4 /*yield*/, exporter.RepresentationSubContext(context, 'Axis', 'Model', 'GRAPH_VIEW')];
          case 4:
            _a.sent();
            return [4 /*yield*/, exporter.RepresentationSubContext(context, 'Body', 'Model', 'MODEL_VIEW')];
          case 5:
            _a.sent();
            return [4 /*yield*/, exporter.RepresentationSubContext(context, 'Box', 'Model', 'MODEL_VIEW')];
          case 6:
            _a.sent();
            return [4 /*yield*/, exporter.RepresentationSubContext(context, 'Footprint', 'Model', 'MODEL_VIEW')];
          case 7:
            _a.sent();
            return [4 /*yield*/, exporter.Project(context, 'web-ifc-three', 'this project was exported from web-ifc-three')];
          case 8:
            project = _a.sent();
            elementsList = [];
            i = 0;
            _a.label = 9;
          case 9:
            if (!(i < objects.length)) return [3 /*break*/, 12];
            meshList = [];
            for (j = 0; j < objects[i].geometries.length; j++) {
              mesh = new three_1.Mesh(objects[i].geometries[j], objects[i].geometryMaterials[j]);
              meshList.push(mesh);
            }
            return [4 /*yield*/, this.exportMeshAsIFCProduct(exporter, meshList, objects[i].ifcElementType, objects[i].ifcElementId, objects[i].placement)];
          case 10:
            ifcElement = _a.sent();
            elementsList.push(ifcElement);
            _a.label = 11;
          case 11:
            i++;
            return [3 /*break*/, 9];
          case 12: return [4 /*yield*/, exporter.Placement({
            x: projectPlacement.x,
            y: projectPlacement.y,
            z: projectPlacement.z
          })];
          case 13:
            placement = _a.sent();
            return [4 /*yield*/, exporter.BuildingStorey(web_ifc_1.IFCBUILDINGSTOREY, placement)];
          case 14:
            buildingStorey = _a.sent();
            return [4 /*yield*/, exporter.Building(web_ifc_1.IFCBUILDING, placement)];
          case 15:
            building = _a.sent();
            return [4 /*yield*/, exporter.Site(web_ifc_1.IFCSITE, placement)];
          case 16:
            site = _a.sent();
            StoreyList = [];
            BuildingList = [];
            SiteList = [];
            StoreyList.push(buildingStorey);
            BuildingList.push(building);
            SiteList.push(site);
            return [4 /*yield*/, exporter.RelContainedInSpatialStructure(web_ifc_1.IFCRELCONTAINEDINSPATIALSTRUCTURE, buildingStorey, elementsList)];
          case 17:
            _a.sent();
            return [4 /*yield*/, exporter.RelAggregates(web_ifc_1.IFCRELAGGREGATES, building, StoreyList)];
          case 18:
            _a.sent();
            return [4 /*yield*/, exporter.RelAggregates(web_ifc_1.IFCRELAGGREGATES, site, BuildingList)];
          case 19:
            _a.sent();
            return [4 /*yield*/, exporter.RelAggregates(web_ifc_1.IFCRELAGGREGATES, project, SiteList)];
          case 20:
            _a.sent();
            return [4 /*yield*/, this.api.ExportFileAsIFC(model)];
          case 21:
            ifcData = _a.sent();
            ifcDataString = new TextDecoder().decode(ifcData);
            console.log(ifcDataString);
            element = document.createElement('a');
            element.setAttribute('href', "data:text/plain;charset=utf-8,".concat(encodeURIComponent(ifcDataString)));
            element.setAttribute('download', 'export.ifc');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            return [2 /*return*/];
        }
      });
    });
  };
  return IFCExporter;
}());
exports.IFCExporter = IFCExporter;
