import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { makeStyles, Fab, Button } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TextField,
  List,
  Paper,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  Badge,
  ListItemText,
  Grid,
  Typography,
} from "@mui/material";
import FolderIcon from '@mui/icons-material/Folder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import BackupIcon from '@mui/icons-material/Backup';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    width: "100%",
  },
  cardExpanded: {
    position: "absolute",
    top: "0px",
    zIndex: 1000,
    left: "0px",
    right: "0px",
    opacity: "0.95",
    width: ({ width }) => width,
    height: ({ height }) => height,
    maxWidth: window.innerWidth - 175,
    maxHeight: window.innerHeight - 175,
  },
  card: {
    position: "absolute",
    top: "0px",
    zIndex: 100,
    left: "0px",
    right: "0px",
    opacity: "0.95",
    width: ({ width }) => width,
    height: ({ height }) => height,
    maxWidth: window.innerWidth - 175,
    maxHeight: window.innerHeight - 175,
  },
  cardContent: {
    opacity: "0.95",
    height: "85%",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
  avatar: {
    backgroundColor: "transparent",
    width: theme.spacing(7),
    height: theme.spacing(7),
    // padding: '5px',
    borderRadius: "0px",
  },
  fab: {
    backgroundColor: "white",
  },
  button: {
    color: "white",
    backgroundColor: "black",
    width: "100%",
    marginTop: "1em",
    marginBottom: "1em",
  },
  linearProgress: {
    width: "90%",
    margin: "1em",
    left: 0,
    right: 0,
    //position: 'absolute',
    // top: '25%',
    // transform: 'translateY(-25%)'
  },
  textField: {
    width: '100%',
    border: '1px'
  },
}));


const ListModels = ({
  models,
  deleteModel,
  showModel
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <br />
        <Typography gutterBottom variant="title2" component="div">
          Liste des maquettes stockées localement:
          </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>File</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {models.length > 0 ?
                <>
                  {models.map(model => (
                    <TableRow
                      key={model.title}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="model">
                        {model.title}
                      </TableCell>
                      <TableCell>{new Date(model.date).toLocaleDateString('en-US')}</TableCell>
                      <TableCell>{`${Math.round(model.size / 1000) / 1000} Mb`}</TableCell>
                      <TableCell>
                        <>
                          <IconButton edge="end" aria-label="delete">
                            <VisibilityIcon onClick={() => showModel(model)} />
                          </IconButton>
                          <IconButton edge="end" aria-label="delete">
                            <BackupIcon onClick={() => showModel(model)} />
                          </IconButton>
                          <IconButton edge="end" aria-label="delete">
                            <DeleteIcon onClick={() => deleteModel(model.title)} />
                          </IconButton>
                        </></TableCell>

                    </TableRow>
                  ))}
                </>
                :
                <TableRow
                  key={0}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="model" colSpan={4}>
                    {'There are no file to show'}
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid >
  );

}

export default ListModels;