import * as React from 'react';
import Modal from '@mui/material/Modal';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import TriDymeIcon from "./img/TriDymeIconV1.svg";
import axios from 'axios';
import jwtDecode from 'jwt-decode';

const {
  REACT_APP_LOGO,
  REACT_APP_COMPANY,
  REACT_APP_TRIDYME,
  REACT_APP_TRIDYME_API
} = process.env;


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: "1em",
  boxShadow: 24,
  p: 4,
  // marginTop: 8,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: "0 5px 8px 0 rgba(110, 65, 65, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19)"
};


export default function UserElem() {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const data = new FormData(event.currentTarget);

      console.log({
        email: data.get('email'),
        password: data.get('password'),
      });
      const res = await axios.post(`${REACT_APP_TRIDYME_API}/login`, {
        email: data.get('email'),
        password: data.get('password'),
      });
      console.log('res', res);
      const { token } = res.data;
      console.log('tridyme_access_token', token);
      console.log('tridyme_user', JSON.stringify(jwtDecode(token)));
      localStorage.setItem('tridyme_access_token', token);
      localStorage.setItem('tridyme_user', JSON.stringify(jwtDecode(token)));
      handleClose();
    } catch (err) {
      setError(true);
    }
  };

  return (
    <>
      <Button color="inherit" variant="contained" onClick={handleOpen} sx={{ mt: 3, mb: 2, bgcolor: "transparent", border: '1px solid #000', }}>Login</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Avatar sx={{ m: 1 }} src={REACT_APP_LOGO} /> */}
          {/* <LockOutlinedIcon /> */}
          {/* <img src='./img/TriDymeIconV1.svg' /> */}
          {/* </Avatar> */}
          <img sx={{ m: 1 }} src={REACT_APP_LOGO} style={{ maxHeight: "2.5em" }} />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, bgcolor: "black" }}
            >
              Sign In
            </Button>
            {(error) &&
              <Grid item xs={12}>
                <Alert severity={'error'}>{`😞 Access denied.`}<a href={`${REACT_APP_TRIDYME}signin`} target="_blank">{` Go to ${REACT_APP_COMPANY} platform`}</a></Alert>
              </Grid>
            }
            <Grid container>
              <Grid item xs>
                <Link href={`${REACT_APP_TRIDYME}forgotPassword`} variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              {REACT_APP_COMPANY === "TriDyme" &&
                <Grid item>
                  <Link href={`${REACT_APP_TRIDYME}signup`} variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              }
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
}