import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { makeStyles, Fab, Button } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import {
  IFCPROJECT,
  IFCSPACE,
  IFCOPENINGELEMENT,
  IFCWALLSTANDARDCASE,
  IFCWALL,
  IFCSTAIR,
  IFCCOLUMN,
  IFCSLAB,
  IFCROOF,
  IFCSTRUCTURALCURVEMEMBER,
  IFCSTRUCTURALANALYSISMODEL,
  IFCSTRUCTURALSURFACEMEMBER,
  IFCFOOTING,
  IFCFURNISHINGELEMENT,
  IFCRELDEFINESBYPROPERTIES,
  IFCPROPERTYSET,
  IFCPROPERTYSINGLEVALUE,
} from "web-ifc";
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  TextField,
  List,
  Paper,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  Badge,
  ListItemText,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Popover,
  Grid,
  LinearProgress,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClearIcon from "@mui/icons-material/Clear";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FolderIcon from '@mui/icons-material/Folder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import BackupIcon from '@mui/icons-material/Backup';

// import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls';
import axios from "axios";
import Dexie from "dexie";

import AddModel from './Components/AddModel';
import ListModels from './Components/ListModels';
import { UseStorage } from './Storage.hooks';

const {
  REACT_APP_TRIDYME_API
} = process.env;

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    width: "100%",
  },
  cardExpanded: {
    position: "absolute",
    top: "0px",
    zIndex: 1000,
    left: "0px",
    right: "0px",
    opacity: "0.95",
    width: ({ width }) => width,
    height: ({ height }) => height,
    maxWidth: window.innerWidth - 175,
    maxHeight: window.innerHeight - 175,
  },
  card: {
    position: "absolute",
    top: "0px",
    zIndex: 100,
    left: "0px",
    right: "0px",
    opacity: "0.95",
    width: ({ width }) => width,
    height: ({ height }) => height,
    maxWidth: window.innerWidth - 175,
    maxHeight: window.innerHeight - 175,
  },
  cardContent: {
    opacity: "0.95",
    height: "85%",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
  avatar: {
    backgroundColor: "transparent",
    width: theme.spacing(7),
    height: theme.spacing(7),
    // padding: '5px',
    borderRadius: "0px",
  },
  fab: {
    backgroundColor: "white",
  },
  button: {
    color: "white",
    backgroundColor: "black",
    width: "100%",
    marginTop: "1em",
    marginBottom: "1em",
  },
  linearProgress: {
    width: "90%",
    margin: "1em",
    left: 0,
    right: 0,
    //position: 'absolute',
    // top: '25%',
    // transform: 'translateY(-25%)'
  },
  textField: {
    width: '100%',
    border: '1px'
  },
}));

const baseStyle = {
  flex: 1,
  display: "flex",
  height: "150px",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  width: "100%",
  borderColor: "#ddddd",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const Storage = ({
  bimData,
  setBimData,
  setShowStorage,
  handleInitSubset
}) => {
  //set the database 
  const db = new Dexie("tribimDB");
  //create the database store
  db.version(1).stores({
    models: "title, content, date, file"
  })
  db.open().catch((err) => {
    console.log(err.stack || err)
  });

  //set the state and property
  const [modelTitle, setModelTitle] = useState("");
  const [modelContent, setModelContent] = useState("");
  const [modelFile, setModelFile] = useState("");
  const [models, setModels] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedView, setExpandedView] = useState(false);
  const [viewWidth, setViewWidth] = useState("100%");
  const [viewHeight, setViewHeight] = useState("100%");

  const props = {
    width: viewWidth,
    height: viewHeight,
  };

  // const {
  // 	projects,
  // 	FetchFiles
  // } = UseStorage(userId);

  const classes = useStyles(props);

  useEffect(() => {
    //get all models from the local database
    let allModels = [];
    const getLocalModels = async () => {
      allModels = await db.models.toArray();
      console.log('allModels', allModels);
      setModels(allModels);
    }
    getLocalModels();

    //get all models from the cloud database
    const user = JSON.parse(localStorage.getItem('tridyme_user'));
    const getCloudModels = async (userId) => {
      const { data } = await axios.get(`${REACT_APP_TRIDYME_API}/filemanager/users/${userId}`);
      const projects = data;
      console.log('projects', projects);
      const newModels = projects.map(project => {
        return {
          title: project.name,
          content: "",
          date: project.LastModified,
          file: project.Key
        }
      });
      let updateModels = [...allModels, ...newModels];
      setModels(updateModels);
    }

    if (user) {
      const userId = user._id;
      getCloudModels(userId);
    }



    const getWidth = () => window.innerWidth - 175;
    const getHeight = () => window.innerHeight - 175;
    const resizeListener = () => {
      if (!expandedView) {
        setViewWidth(getWidth());
        setViewHeight(getHeight());
      }
    };
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const handleExpandView = (e) => {
    const width = window.innerWidth - 175;
    const height = window.innerHeight - 175;
    console.log('width', width)
    console.log('expandedView', expandedView)
    if (!expandedView) {
      setExpandedView(true);
      setViewWidth(width);
      setViewHeight(height);
      setAnchorEl(null);
    } else if (expandedView) {
      setExpandedView(false);
      setViewWidth("100%");
      setViewHeight("100%");
      setAnchorEl(null);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteModel = async (id) => {
    console.log(id);
    db.models.delete(id);
    let allModels = await db.models.toArray();
    //set the models
    setModels(allModels);
  }

  const ifcOnLoadError = async (err) => {
    alert(err.toString());
  };

  const showModel = async (model) => {
    try {
      setBimData({
        ...bimData,
        loading: true
      });

      bimData.viewer.IFC.loader.ifcManager.setOnProgress((event) => {
        const percentage = Math.floor((event.loaded * 100) / event.total);
        setBimData({
          ...bimData,
          loading: true,
          loadingMessage: `Chargement: ${percentage} %`
        });
      });

      bimData.viewer.IFC.loader.ifcManager.parser.setupOptionalCategories({
        [IFCSPACE]: true,
        [IFCOPENINGELEMENT]: false,
        [IFCSTRUCTURALANALYSISMODEL]: true,
        [IFCSTRUCTURALSURFACEMEMBER]: true,
        [IFCSTRUCTURALCURVEMEMBER]: true,
      });

      console.log('model', model.file)

      let newModel;
      try {
        newModel = await bimData.viewer.IFC.loadIfcUrl(model.file, true);
        // console.log('data', properties)
      } catch (error) {
        console.log('error:', error)
        return
      }


      await handleInitSubset(bimData.viewer, newModel.modelID);

      // await bimData.viewer.IFC.loadIfcUrl(model.file, true);
      const newSpatialStructure = await bimData.viewer.IFC.getSpatialStructure(
        newModel.modelID,
        false
      );

      const updateSpatialStructures = [
        ...bimData.spatialStructures.list,
        newSpatialStructure,
      ];
      // setSpatialStructures(updateSpatialStructures);

      setBimData({
        ...bimData,
        loading: false,
        spatialStructures: {
          value: { ...newSpatialStructure },
          list: [...updateSpatialStructures]
        }
      });
      // console.log('data', properties)
    } catch (error) {
      console.log('error:', error)
      return
    }

  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (

    <Card className={expandedView ? classes.cardExpanded : classes.card} >
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            <Fab size="small" className={classes.fab}>
              <FactCheckIcon />
            </Fab>
          </Avatar>
        }
        title={`Sauvegarde`}
        subheader={`Système de sauvegarde de fichier IFC`}
        action={
          <div>
            <IconButton
              aria-label="settings"
              aria-describedby={id}
              onClick={handleExpandView}
              size="small"
            >
              {expandedView ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
            <IconButton
              aria-label="settings"
              aria-describedby={id}
              onClick={() => setShowStorage(false)}
              size="small"
            >
              <ClearIcon />
            </IconButton>
            <IconButton
              aria-label="settings"
              aria-describedby={id}
              onClick={handleClick}
              size="small"
            >
              <MoreVertIcon />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {/* {logs.length > 0 && (
                <ListItem button onClick={handleExportToCsv}>
                  <ListItemIcon>
                    <DownloadIcon />
                  </ListItemIcon>
                  <ListItemText primary="Télécharger CSV" />
                </ListItem>
              )}
              <ListItem button onClick={() => setShowValidation(false)}>
                <ListItemIcon>
                  <ClearIcon />
                </ListItemIcon>
                <ListItemText primary="Fermer" />
              </ListItem> */}
            </Popover>
          </div>
        }
      />
      <CardContent className={classes.cardContent}>
        <AddModel
          db={db}
          setModels={setModels}
        />
        <ListModels
          models={models}
          deleteModel={deleteModel}
          showModel={showModel}
        />
      </CardContent>
    </Card>
  );

}

export default Storage;