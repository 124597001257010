import React, { useEffect, useRef, useState, memo } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Checkbox,
  FormControlLabel,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  Popover,
  // List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Tabs,
  Tab,
  Chip,
  ListItemButton,
  Grid,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  SvgIcon
} from "@mui/material";
import UploadIcon from '@mui/icons-material/Upload';
import CSVFileIcon from './img/CSVFileIcon.svg';
import Dropzone from "react-dropzone";
import Papa from 'papaparse';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    width: "100%",
  },
  cardInfo: {
    zIndex: 100,
    width: "100%",
    height: "100%",
  },
  cardContent: {
    height: "90%",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
  treeViewLabel: {
    left: "3em",
    position: "absolute",
    textOverflow: "ellipsis",
    overflow: "hidden",
    wordWrap: "break-word",
    warp: true,
    // width: '5em'
  },
  treeViewCheckbox: {
    margin: 0,
    padding: 0,
  },
  treeView: {
    // height: 240,
    flexGrow: 1,
    // maxWidth: 400,
  },
  buttonGroup: {
    backgroundColor: 'white',
    marginTop: '1em'
  }
}));


const ImportData = ({
  eids,
  viewer,
  handleEditIfcProperties
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const dropzonePropertyRef = useRef(null);
  const dropzoneElementRef = useRef(null);

  function readFile(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = event => resolve(event.target.result);
      reader.onerror = error => reject(error);
      reader.readAsText(file);
    });
  }


  const handleImportFromCsv = async ({ files, viewer }) => {
    const fileContent = await readFile(files[0]);

    const res = await Papa.parse(fileContent, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transform: (value) => {
        return value.replace(/\.|,/g, '.');
      }
    });
    console.log('CSV', res.data)
    await handleEditIfcProperties({
      viewer,
      modelID: 0,
      properties: res.data
    });
  }


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickPropertyOpen = () => {
    dropzonePropertyRef.current.open();
  };

  const handleClickElementOpen = () => {
    dropzoneElementRef.current.open();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Dropzone ref={dropzonePropertyRef} onDrop={files => handleImportFromCsv({ files, viewer })}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} accept=".csv" />
          </div>
        )}
      </Dropzone>
      <Dropzone ref={dropzoneElementRef} onDrop={files => handleImportFromCsv({ files, viewer })}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} accept=".csv" />
          </div>
        )}
      </Dropzone>
      <Button
        edge="end"
        aria-label="comments"
        className={classes.button}
        aria-describedby={id}
        onClick={handleClick}
      >
        <UploadIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ListItem
          button
          onClick={handleClickPropertyOpen}
        >
          <ListItemIcon>
            {/* <SvgIcon component={CSVFileIcon} inheritViewBox /> */}
            <img src={CSVFileIcon} style={{ width: '2em', height: '2em' }} />
          </ListItemIcon>
          <ListItemText primary="Import propriétées" />
        </ListItem>
        {/* <ListItem
          button
          onClick={handleClickElementOpen}
        >
          <ListItemIcon>
            <img src={CSVFileIcon} style={{ width: '2em', height: '2em' }} />
          </ListItemIcon>
          <ListItemText primary="Import éléments" />
        </ListItem> */}
      </Popover>
    </>
  );
};

export default ImportData;
