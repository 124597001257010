import React, { useEffect, useRef, useState, memo } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Checkbox,
  FormControlLabel,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  Popover,
  // List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Tabs,
  Tab,
  Chip,
  ListItemButton,
  Grid,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  SvgIcon
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import CSVFileIcon from './img/CSVFileIcon.svg';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    width: "100%",
  },
  cardInfo: {
    zIndex: 100,
    width: "100%",
    height: "100%",
  },
  cardContent: {
    height: "90%",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
  treeViewLabel: {
    left: "3em",
    position: "absolute",
    textOverflow: "ellipsis",
    overflow: "hidden",
    wordWrap: "break-word",
    warp: true,
    // width: '5em'
  },
  treeViewCheckbox: {
    margin: 0,
    padding: 0,
  },
  treeView: {
    // height: 240,
    flexGrow: 1,
    // maxWidth: 400,
  },
  buttonGroup: {
    backgroundColor: 'white',
    marginTop: '1em'
  }
}));


const ExportData = ({
  eids,
  viewer
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const downloadFile = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }


  const handleExportPropertiesToCsv = async (e, eids) => {
    e.preventDefault()
    // Headers for each column
    let propertiesCsv = [];
    let headers = ['modelId', 'elementName', 'elementClass', 'elementExpressID', 'globalId', 'psetName', 'propertyExpressId', 'propertyType', 'propertyName', 'propertyValue'].join(',');
    propertiesCsv.push(headers)

    await Promise.all(eids.map(async eid => {
      const ifcElement = await viewer.IFC.getProperties(0, eid, true, true);
      const selectedModelID = await viewer.IFC.getModelID();
      const ifcClass = viewer.IFC.loader.ifcManager.getIfcType(
        0,
        eid
      );
      // const ifcLoader = new IFCLoader();
      // const ifcClass = ifcClassType[];
      console.log('ifcClass ', ifcClass)
      await Promise.all(ifcElement.psets?.map(async pset => pset.HasProperties && await Promise.all(pset.HasProperties?.map(async (property) => {
        const modelID = 0;
        const elementName = `${ifcElement.Name?.value}`;
        const elementClass = ifcClass ? ifcClass : "NO TYPE";
        const elementExpressID = `${ifcElement.expressID}`;
        const globalID = `${ifcElement.GlobalId.value}`;
        const psetName = `${pset.Name.value}`;
        const propertyExpressID = `${property.expressID}`;
        const propertyType = `${property.NominalValue?.label}`;
        const propertyName = `${property.Name?.value}`;
        const propertyValue = `${property.NominalValue?.value}`;

        propertiesCsv.push([modelID, elementName, elementClass, elementExpressID, globalID, psetName, propertyExpressID, propertyType, propertyName, propertyValue].join(','))
      }))))
    }));

    downloadFile({
      data: [...propertiesCsv].join('\n'),
      fileName: `properties.csv`,
      fileType: 'text/csv',
    })
  }

  const handleExportElementsToCsv = async (e, eids) => {
    e.preventDefault()
    // Headers for each column
    let elementsCsv = [];
    let headers = ['modelId', 'elementName', 'elementClass', 'elementExpressID', 'globalId', 'color', 'hidden'].join(',');
    elementsCsv.push(headers)

    await Promise.all(eids.map(async eid => {
      const ifcElement = await viewer.IFC.getProperties(0, eid, true, true);
      const selectedModelID = await viewer.IFC.getModelID();
      const ifcClass = viewer.IFC.loader.ifcManager.getIfcType(
        0,
        eid
      );

      const modelID = 0;
      const elementName = `${ifcElement.Name?.value}`;
      const elementClass = ifcClass ? ifcClass : "NO TYPE";
      const elementExpressID = `${ifcElement.expressID}`;
      const globalID = `${ifcElement.GlobalId.value}`;
      const color = null;
      const hidden = false;

      elementsCsv.push([modelID, elementName, elementClass, elementExpressID, globalID, color, hidden].join(','))
    }));

    downloadFile({
      data: [...elementsCsv].join('\n'),
      fileName: `elements.csv`,
      fileType: 'text/csv',
    })
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        edge="end"
        aria-label="comments"
        className={classes.button}
        aria-describedby={id}
        onClick={handleClick}
      >
        <DownloadIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ListItem
          button
          onClick={(e) => handleExportPropertiesToCsv(e, eids)}
        >
          <ListItemIcon>
            {/* <SvgIcon component={CSVFileIcon} inheritViewBox /> */}
            <img src={CSVFileIcon} style={{ width: '2em', height: '2em' }} />
          </ListItemIcon>
          <ListItemText primary="Export propriétées" />
        </ListItem>
        <ListItem
          button
          onClick={(e) => handleExportElementsToCsv(e, eids)}
        >
          <ListItemIcon>
            <img src={CSVFileIcon} style={{ width: '2em', height: '2em' }} />
          </ListItemIcon>
          <ListItemText primary="Export éléments" />
        </ListItem>
      </Popover>
    </>
  );
};

export default ExportData;
