import { useEffect, useState } from 'react';
import axios from "axios";

const {
  REACT_APP_THIRD_PARTY_API,
  REACT_APP_COS_API_TENANT,
  REACT_APP_COS_API_TOKEN,
  REACT_APP_COS_API_URL,
  REACT_APP_COS_API_LOGIN,
  REACT_APP_COS_API_PASSWORD
} = process.env;

const postLogin = async () => {
  try {
    console.log('REACT_APP_COS_API_URL', REACT_APP_COS_API_URL)
    console.log('REACT_APP_COS_API_TENANT', REACT_APP_COS_API_TENANT)
    console.log('REACT_APP_COS_API_LOGIN', REACT_APP_COS_API_LOGIN)
    console.log('REACT_APP_COS_API_PASSWORD', REACT_APP_COS_API_PASSWORD)
    const response = await axios({
      method: "post",
      url: `${REACT_APP_COS_API_URL}/identity/login`,
      headers: {
        "content-type": "application/json",
        "X-Client-Tenant": `${REACT_APP_COS_API_TENANT}`
        // "X-Auth-Token": sessionStorage.getItem("token"),
      },
      data: {
        loginName: `${REACT_APP_COS_API_LOGIN}`,
        password: `${REACT_APP_COS_API_PASSWORD}`,
      },
    });

    // token = response.data.token;
    // console.log('token', response.data);

    return {
      token: response.data.token,
      userId: response.data.userId,
      username: response.data.username
    }
  } catch (err) {
    console.log({ 'COS Login': err });
    // return res.status(500).json({ error: err });
  }
};





function UseCos({
  bimData
}) {
  const [state, setState] = useState({
    bimData,
    loading: false,
    views: {
      value: "capacity",
      list: []
    },
    search: {
      value: '',
      list: []
    },
    domains: {
      value: "",
      list: [],
      selection: []
    },
    classifications: {
      value: "",
      list: []
    },
    properties: {
      value: "",
      list: []
    }
  });

  async function handleGetStructureEquipments() {
    try {
      const entityType = "structure";
      const response = await axios({
        method: "post",
        url: `http://localhost:8080/cos/entity/filter/${entityType}`,
        headers: {
          "content-type": "application/json"
        }
      });

      console.log('response', response.data)

    } catch (err) {
      console.log('err', err)
    }
  }

  async function handleAllEquipments() {
    try {
      // const res = await axios({
      //   method: "get",
      //   url: `${REACT_APP_THIRD_PARTY_API}/bsdd/country`
      // })
      // console.log('res', res.data)
    } catch (err) {
      console.log('err', err)
    }
  }

  async function handleCapacityAnalysis() {

  }

  async function handleDownloadCalculationNote() {

  }

  return {
    state,
    setState,
    handleGetStructureEquipments,
    handleAllEquipments,
    handleCapacityAnalysis,
    handleDownloadCalculationNote
  }
};

export {
  UseCos
};