import { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  Button,
  Typography,
  Step,
  StepLabel,
  withStyles,
  Stepper,
} from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import axios from "axios";
import { Web3Storage } from 'web3.storage/dist/bundle.esm.min.js';
import ObjectID from './utils/generateObjectId';
import inputs from './inputs.json';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      backgroundColor: "white",
    },
  },
  button: {
    backgroundColor: "#E6464D",
    color: "white",
    "&:hover": {
      backgroundColor: "#E6464D",
      color: "white",
    },
    "&:disabled": {
      opacity: 0.8,
      color: "white",
    },
  },
  navigationBar: {
    margin: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
    padding: "10px",
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  modalDatBim: {
    width: "50%",
    height: "70%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "hidden scroll",
    position: "relative",
  },
  datBimCard: {
    backgroundColor: "#E6464D",
    color: "white",
    margin: theme.spacing(1),
    cursor: "pointer",
  },
  datBimTitle: {
    textAlign: "center",
    // color: '#E6464D',
    textTransform: "none",
  },
  datBimCardTitle: {
    margin: 0,
    color: "white",
  },
  datBimFooterCard: {
    display: "block",
    textAlign: "right",
  },
  datBimCardButton: {
    textAlign: "right",
    color: "white",
  },
  accordionDetails: {
    display: "block",
  },
  datBimIcon: {
    width: "3em",
  },
}));


const {
  REACT_APP_THIRD_PARTY_API
} = process.env;




const TriStructure = ({
  openProperties,
  projectId,
  objSelected,
  viewer,
  modelID,
  eids,
  setEids,
  addElementsNewProperties,
  handleShowMarketplace
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [accessToken, setAccessToken] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    const init = async () => {
      const newViewer = {
        ...viewer,
        structures: []
      }
      console.log('newViewer', newViewer);
      console.log('inputs', inputs);
    }
    init();
  }, []);

  const handleStructureAnalysis = async () => {
    // const projectsInfo = await axios.get(`http://backend.hivory.tridyme.com/api/users/projectsCommand?limit=*`, {
    //   headers: {
    //     'x-api-key': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN1cGVyLmFkbWluQGFyeWF0b3dlcnMuY29tIiwiX2lkIjoiNTg5OWUwYWNhNjAwNzQxNzU1NDMzOTIxIiwiaWF0IjoxNjM5NTg0NzM5fQ.GUdD7IG2w7mclX7XzClTHXnBpUrLllUOn0FlDYvWgjo`
    //   }
    // });
    // console.log('projectsInfo', projectsInfo.data.projects.length);

    const response = await axios({
      method: "post",
      url: `http://localhost:8080/api/quickStructuralAnalysis`,
      headers: {
        "content-type": "application/json",
        'x-api-key': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN1cGVyLmFkbWluQGFyeWF0b3dlcnMuY29tIiwiX2lkIjoiNTg5OWUwYWNhNjAwNzQxNzU1NDMzOTIxIiwiaWF0IjoxNjM5NDA0NTIxfQ.b8VD9iSkN4D3XZvo69dm1kAUIeY2xWkYhB5km8oRCuA`
      },
      data: inputs,
    });

    console.log('res', response.data)
  }


  const material0Id = ObjectID();
  const section0Id = ObjectID();
  const support0Id = ObjectID();
  const node0Id = ObjectID();
  const node1Id = ObjectID();
  const member0Id = ObjectID();
  const loadCase0Id = ObjectID();
  const loadCombination0Id = ObjectID();
  const nodeLoad0Id = ObjectID();
  const memberLoad0Id = ObjectID();


  return (
    <Grid container>
      <Grid item xs={12}>
        <Button
          className={classes.button}
          onClick={handleStructureAnalysis}
        >
          Calcul
        </Button>
      </Grid>
    </Grid>
  );
};

export default TriStructure;
