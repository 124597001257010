import * as wifc from 'web-ifc';

// let EID = 100000;

function real(v) {
  return { type: 4, value: v }
}

function ref(v) {
  return { type: 5, value: v }
}

function empty() {
  return { type: 6 }
}

function str(v) {
  return { type: 1, value: v }
}

function enm(v) {
  return { type: 3, value: v }
}


export class TriDymeExportHelper {
  constructor(m, api, EID) {
    this.model = m;
    this.api = api;
    this.EID = EID;
  }

  async Write(lineObject) {
    console.log('EID', this.EID)
    const rawLineData = {
      ID: lineObject.expressID,
      type: lineObject.type,
      arguments: lineObject.ToTape()
    };

    await this.api.WriteRawLineData(this.model, rawLineData);
  }

  async Point(o) {
    const ID = this.EID++;
    const pt = new wifc.IfcCartesianPoint(ID, wifc.IFCCARTESIANPOINT, [
      real(o.x),
      real(o.y),
      real(o.z)
    ]);
    await this.Write(pt);
    return ref(ID);
  }

  async PolyLoop(os) {
    const refs = await Promise.all(os.map((o) => this.Point(o)));
    const ID = this.EID++;
    const loop = new wifc.IfcPolyLoop(ID, wifc.IFCPOLYLOOP, refs);
    await this.Write(loop);
    return ref(ID);
  }

  async FaceOuterBound(os) {
    const ID = this.EID++;
    const bound = new wifc.IfcFaceOuterBound(
      ID,
      wifc.IFCFACEOUTERBOUND,
      await this.PolyLoop(os),
      enm('T')
    );
    await this.Write(bound);
    return ref(ID);
  }

  async Face(os) {
    const ID = this.EID++;
    const face = new wifc.IfcFace(ID, wifc.IFCFACE, [await this.FaceOuterBound(os)]);
    await this.Write(face);
    return ref(ID);
  }

  async ClosedShell(faceRefs) {
    const ID = this.EID++;
    const shell = new wifc.IfcClosedShell(ID, wifc.IFCCLOSEDSHELL, faceRefs);
    await this.Write(shell);
    return ref(ID);
  }

  async FacetedBREP(faceRefs) {
    const ID = this.EID++;
    const brep = new wifc.IfcFacetedBrep(ID, wifc.IFCFACETEDBREP, await this.ClosedShell(faceRefs));
    await this.Write(brep);
    return ref(ID);
  }

  async ColourRGB(r, g, b) {
    const ID = this.EID++;
    const col = new wifc.IfcColourRgb(ID, wifc.IFCCOLOURRGB, empty(), real(r), real(g), real(b));
    await this.Write(col);
    return ref(ID);
  }

  async SurfaceStyleShading(r, g, b, a) {
    const ID = this.EID++;
    const col = new wifc.IfcSurfaceStyleShading(
      ID,
      wifc.IFCSURFACESTYLESHADING,
      await this.ColourRGB(r, g, b),
      real(a)
    );
    await this.Write(col);
    return ref(ID);
  }

  async SurfaceStyleRendering(r, g, b, a) {
    const ID = this.EID++;
    const col = new wifc.IfcSurfaceStyleRendering(
      ID,
      wifc.IFCSURFACESTYLERENDERING,
      await this.ColourRGB(r, g, b),
      real(a),
      empty(),
      empty(),
      empty(),
      empty(),
      empty(),
      empty(),
      enm('NOTDEFINED')
    );
    await this.Write(col);
    return ref(ID);
  }

  async SurfaceStyle(name, r, g, b, a) {
    const ID = this.EID++;
    const col = new wifc.IfcSurfaceStyle(
      ID,
      wifc.IFCSURFACESTYLE,
      str(name),
      enm(wifc.IfcSurfaceSide.BOTH),
      [await this.SurfaceStyleShading(r, g, b, a)]
    );
    await this.Write(col);
    return ref(ID);
  }

  async PresentationStyleAssignment(
    name,
    r,
    g,
    b,
    a
  ) {
    const ID = this.EID++;
    const style = new wifc.IfcPresentationStyleAssignment(ID, wifc.IFCPRESENTATIONSTYLEASSIGNMENT, [
      await this.SurfaceStyle(name, r, g, b, a)
    ]);
    await this.Write(style);
    return ref(ID);
  }

  async ShapePresentationStyleAssignment(
    name,
    r,
    g,
    b,
    a
  ) {
    const ID = this.EID++;
    const style = new wifc.IfcPresentationStyleAssignment(ID, wifc.IFCPRESENTATIONSTYLEASSIGNMENT, [
      await this.ShapeStyleAssignment(name, r, g, b, a)
    ]);
    await this.Write(style);
    return ref(ID);
  }

  async ShapeStyleAssignment(
    name,
    r,
    g,
    b,
    a
  ) {
    const ID = this.EID++;
    const style = new wifc.IfcSurfaceStyle(ID, wifc.IFCSURFACESTYLE, str(name), enm('BOTH'), [
      await this.SurfaceStyleRendering(r, g, b, a)
    ]);
    await this.Write(style);
    return ref(ID);
  }

  async StyledItem(item, style) {
    const ID = this.EID++;
    const s = new wifc.IfcStyledItem(ID, wifc.IFCSTYLEDITEM, item, [style], empty());
    await this.Write(s);
    return ref(ID);
  }

  async StyledItemContext(style) {
    const ID = this.EID++;
    const s = new wifc.IfcStyledItem(ID, wifc.IFCSTYLEDITEM, empty(), [style], empty());
    await this.Write(s);
    return ref(ID);
  }

  async StyledRepresentationContext(
    context,
    name,
    description,
    style
  ) {
    const ID = this.EID++;
    const s = new wifc.IfcStyledRepresentation(
      ID,
      wifc.IFCSTYLEDREPRESENTATION,
      context,
      str(name),
      str(description),
      style
    );
    await this.Write(s);
    return ref(ID);
  }

  async ShapeBREP(brepRefs) {
    const ID = this.EID++;
    const shape = new wifc.IfcShapeRepresentation(
      ID,
      wifc.IFCSHAPEREPRESENTATION,
      empty(),
      str('Body'),
      str('Brep'),
      brepRefs
    );
    await this.Write(shape);
    return ref(ID);
  }

  async ProductDefinitionShape(shapeRefs) {
    const ID = this.EID++;
    const def = new wifc.IfcProductDefinitionShape(
      ID,
      wifc.IFCPRODUCTDEFINITIONSHAPE,
      empty(),
      empty(),
      shapeRefs
    );
    await this.Write(def);
    return ref(ID);
  }

  async Product(constructor, typeID, productShape, placement) {
    const ID = this.EID++;
    const pt = new constructor(
      ID,
      typeID,
      str(Math.random().toString(16).substring(2, 8)),
      empty(),
      str('name'),
      empty(),
      str('label'),
      placement,
      productShape,
      str(''),
      empty()
    );
    await this.Write(pt);
    return ref(ID);
  }

  async Building(typeID, placement) {
    const ID = this.EID++;
    const pt = new wifc.IfcBuilding(
      ID,
      typeID,
      str(Math.random().toString(16).substr(2, 8)),
      empty(),
      str('name'),
      str('description'),
      str('label'),
      placement,
      empty(),
      str(''),
      enm(wifc.IfcElementCompositionEnum.ELEMENT),
      empty(),
      empty(),
      empty()
    );
    await this.Write(pt);
    return ref(ID);
  }

  async BuildingStorey(typeID, placement) {
    const ID = this.EID++;
    const pt = new wifc.IfcBuildingStorey(
      ID,
      typeID,
      str(Math.random().toString(16).substr(2, 8)),
      empty(),
      str('name'),
      str('description'),
      str('label'),
      placement,
      empty(),
      str(''),
      enm(wifc.IfcElementCompositionEnum.ELEMENT),
      empty()
    );
    await this.Write(pt);
    return ref(ID);
  }

  async Site(typeID, placement) {
    const ID = this.EID++;
    const pt = new wifc.IfcSite(
      ID,
      typeID,
      str(Math.random().toString(16).substr(2, 8)),
      empty(),
      str('name'),
      str('description'),
      str('label'),
      placement,
      empty(),
      str(''),
      enm(wifc.IfcElementCompositionEnum.ELEMENT),
      empty(),
      empty(),
      empty(),
      empty(),
      empty()
    );
    await this.Write(pt);
    return ref(ID);
  }

  async RelContainedInSpatialStructure(
    typeID,
    buildingStorey,
    elementsList
  ) {
    const ID = this.EID++;
    const pt = new wifc.IfcRelContainedInSpatialStructure(
      ID,
      typeID,
      str(Math.random().toString(16).substr(2, 8)),
      empty(),
      str('name'),
      str('description'),
      elementsList,
      buildingStorey
    );
    await this.Write(pt);
    return ref(ID);
  }

  async RelAggregates(typeID, element, elementsList) {
    const ID = this.EID++;
    const pt = new wifc.IfcRelAggregates(
      ID,
      typeID,
      str(Math.random().toString(16).substr(2, 8)),
      empty(),
      str('name'),
      str('description'),
      element,
      elementsList
    );
    await this.Write(pt);
    return ref(ID);
  }

  async Dir(o) {
    const ID = this.EID++;
    const pt = new wifc.IfcDirection(ID, wifc.IFCDIRECTION, [real(o.x), real(o.y), real(o.z)]);
    await this.Write(pt);
    return ref(ID);
  }

  async Point2D(o) {
    const ID = this.EID++;
    const pt = new wifc.IfcCartesianPoint(ID, wifc.IFCCARTESIANPOINT, [real(o.x), real(o.y)]);
    await this.Write(pt);
    return ref(ID);
  }

  async AxisPlacement(o) {
    const locationID = await this.Point(o);
    const ID = this.EID++;
    const pt = new wifc.IfcAxis2Placement3D(
      ID,
      wifc.IFCAXIS2PLACEMENT3D,
      locationID,
      empty(),
      empty()
    );
    await this.Write(pt);
    return ref(ID);
  }

  async AxisPlacement2D(o) {
    const locationID = await this.Point2D(o);
    const ID = this.EID++;
    const pt = new wifc.IfcAxis2Placement2D(ID, wifc.IFCAXIS2PLACEMENT2D, locationID, empty());
    await this.Write(pt);
    return ref(ID);
  }

  async Placement(o) {
    const axisID = await this.AxisPlacement(o);
    const ID = this.EID++;
    const pt = new wifc.IfcLocalPlacement(ID, wifc.IFCLOCALPLACEMENT, empty(), axisID);
    await this.Write(pt);
    return ref(ID);
  }

  async CircleProfile(rad, o) {
    const ID = this.EID++;
    const pt = new wifc.IfcCircleProfileDef(
      ID,
      wifc.IFCCIRCLEPROFILEDEF,
      enm(wifc.IfcProfileTypeEnum.AREA),
      str('column-prefab'),
      await this.AxisPlacement2D(o),
      real(rad)
    );
    await this.Write(pt);
    return ref(ID);
  }

  async Project(context, name, description) {
    const ID = this.EID++;
    const pt = new wifc.IfcProject(
      ID,
      wifc.IFCPROJECT,
      str(Math.random().toString(16).substr(2, 8)),
      empty(),
      str(name),
      str(description),
      empty(),
      empty(),
      empty(),
      [context],
      await this.UnitAssignment()
    );
    await this.Write(pt);
    return ref(ID);
  }

  async RepresentationContext(pos, north) {
    const ID = this.EID++;
    const pt = new wifc.IfcGeometricRepresentationContext(
      ID,
      wifc.IFCGEOMETRICREPRESENTATIONCONTEXT,
      str('Model'),
      empty(),
      real(3),
      real(1e-5),
      await this.AxisPlacement(pos),
      await this.Dir(north)
    );
    await this.Write(pt);
    return ref(ID);
  }

  async RepresentationSubContext(
    context,
    identifier,
    description,
    enumerated
  ) {
    const ID = this.EID++;
    const pt = new wifc.IfcGeometricRepresentationSubContext(
      ID,
      wifc.IFCGEOMETRICREPRESENTATIONSUBCONTEXT,
      str(identifier),
      str(description),
      str('*'),
      str('*'),
      str('*'),
      str('*'),
      context,
      empty(),
      enm(enumerated),
      empty()
    );
    await this.Write(pt);
    return ref(ID);
  }

  async UnitAssignment() {
    const ID = this.EID++;
    const lst = [];
    lst.push(await this.SiUnit(enm(wifc.IfcUnitEnum.LENGTHUNIT), enm(wifc.IfcSIUnitName.METRE)));
    lst.push(
      await this.SiUnit(enm(wifc.IfcUnitEnum.AREAUNIT), enm(wifc.IfcSIUnitName.SQUARE_METRE))
    );
    lst.push(
      await this.SiUnit(enm(wifc.IfcUnitEnum.VOLUMEUNIT), enm(wifc.IfcSIUnitName.CUBIC_METRE))
    );
    lst.push(await this.SiUnit(enm(wifc.IfcUnitEnum.MASSUNIT), enm(wifc.IfcSIUnitName.GRAM)));
    lst.push(
      await this.SiUnit(enm(wifc.IfcUnitEnum.SOLIDANGLEUNIT), enm(wifc.IfcSIUnitName.STERADIAN))
    );
    lst.push(await this.SiUnit(enm(wifc.IfcUnitEnum.TIMEUNIT), enm(wifc.IfcSIUnitName.SECOND)));
    lst.push(
      await this.SiUnit(
        enm(wifc.IfcUnitEnum.THERMODYNAMICTEMPERATUREUNIT),
        enm(wifc.IfcSIUnitName.DEGREE_CELSIUS)
      )
    );
    lst.push(
      await this.SiUnit(enm(wifc.IfcUnitEnum.LUMINOUSINTENSITYUNIT), enm(wifc.IfcSIUnitName.LUMEN))
    );
    const pt = new wifc.IfcUnitAssignment(ID, wifc.IFCUNITASSIGNMENT, lst);
    await this.Write(pt);
    return ref(ID);
  }

  async SiUnit(unit, name) {
    const ID = this.EID++;
    const pt = new wifc.IfcSIUnit(ID, wifc.IFCSIUNIT, empty(), unit, empty(), name);
    await this.Write(pt);
    return ref(ID);
  }

  async ExtrudedAreaSolid(pos, dir, rad, len) {
    const ID = this.EID++;
    const pt = new wifc.IfcExtrudedAreaSolid(
      ID,
      wifc.IFCEXTRUDEDAREASOLID,
      await this.CircleProfile(rad, { x: 0, y: 0 }),
      await this.AxisPlacement(pos),
      await this.Dir(dir),
      real(len)
    );
    await this.Write(pt);
    return ref(ID);
  }
}
